import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useAxiosWithToken } from "utils/AxiosWithToken";
import { groupBy } from "lodash";

const DropdownLokasiObservasi = (props) => {
  // const [selected, setSelected] = useState(props.value);
  // const [selectedText, setSelectedText] = useState(props.text);

  const [{ data, loading, error }, refetch] = useAxiosWithToken(
    "/ref/lokasi_observasi"
  );

  if (loading) return "loading list..";
  if (error) return "error when loading list of lokasi observasi..";

  var options = [];

  data.map((val, key) => {
    options.push({
      key: val.id_lokasi_observasi,
      value: val.id_lokasi_observasi,
      // text: `${val.nama_lokasi}`,
      text: `${val.nama_lokasi}, ${val.gedung}`,
      gedung: val.gedung,
    });
  });

  const groups = groupBy(options, (lokasi) => lokasi.gedung);
  // console.log(groups);

  const content = [];
  Object.keys(groups).map((key, index) => {
    content.push(<Dropdown.Divider />);
    content.push(<Dropdown.Header content={key} />);
    groups[key].map((val, idx) => {
      // console.log("Item: ", key, val.text);
      content.push(
        <Dropdown.Item
          key={val.key}
          value={val.value}
          text={val.text}
          onClick={() => {
            console.log("Value changed!!", val.value);
            props.onChange(val.value);
          }}
        />
      );
    });
  });
  // console.log(content);

  return (
    <Dropdown
      {...props}
      options={options}
      // options={content}
      selection
      search
      value={props.value ? props.value : null}
      style={{ minWidth: 120 }}
      onChange={(e, f) => {
        // handleChange(f.value);
        console.log("Value changed!!", f);
        props.onChange(f.value);
      }}
    />
  );
};

export default DropdownLokasiObservasi;

{
  /* <Dropdown.Header content={"Gedung A"} />
<Dropdown.Item text={"Ruang A1"} />
<Dropdown.Item text={"Ruang A2"} />
<Dropdown.Item text={"Ruang A3"} />
<Dropdown.Divider />
<Dropdown.Header content={"Gedung B"} />
<Dropdown.Item text={"Ruang B1"} />
<Dropdown.Item text={"Ruang B2"} />
<Dropdown.Item text={"Ruang B3"} /> */
}
