import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { useDebounce } from "use-debounce";

import { MediaContextProvider, Media } from "utils/MediaUtils";
import { useWindowHeight } from "@react-hook/window-size";
import DropdownFilter from "components/dropdowns/DropdownFilter";
// import { useAxiosWithToken } from "utils/AxiosWithToken";
import { useAxiosWithToken } from "utils/AxiosWithToken";

import {
  Breadcrumb,
  Button,
  Container,
  // Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Menu,
  Segment,
  Tab,
  Table,
} from "semantic-ui-react";
// import Subheader from "components/elements/Subheader";
import StatNumber from "components/elements/StatNumber";
import PlaceholderPara from "components/elements/PlaceholderPara";

import { useHistory } from "react-router-dom";

const Head = (props) => {
  const sections = [
    { key: "beranda", content: "Beranda", link: true },
    { key: "inventarisasi", content: "Inventarisasi", link: true },
  ];
  return (
    <div>
      <Breadcrumb
        icon="right angle"
        sections={sections}
        style={{ marginLeft: 20 }}
      />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        Inventarisasi Koleksi
      </Header>
    </div>
  );
};

const Migrasi = () => {
  return (
    <Tab.Pane>
      <Grid columns={1} stretched={true}>
        <Grid.Column verticalAlign="middle" textAlign="center">
          <Image centered src="/images/server_transfer.png" />
        </Grid.Column>
      </Grid>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <StatNumber icon="check" number={192912} label="Data Master" />
          </Grid.Column>
          <Grid.Column verticalAlign="middle" textAlign="center">
            <Button primary>Sinkronisasi</Button>
          </Grid.Column>
          <Grid.Column>
            <StatNumber icon="check" number={162212} label="Data Slave" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

const Data = (props) => {
  const recPerPage = 15;
  const { size } = props;
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("nama_benda");
  const [debFilter] = useDebounce(filter, 1000);

  const handleChangeFilterCriteria = (v) => {
    setFilterCriteria(v);
  };
  const handleChangeFilter = (e) => {
    setFilter(e.target.value);
    setOffset(0);
  };
  const handleNext = () => {
    setOffset(offset + recPerPage);
  };
  const handlePrev = () => {
    setOffset(offset > 0 ? offset - recPerPage : 0);
  };

  const handleShowDetail = (id) => {
    var encodedId = encodeURIComponent(id);
    console.log(`Clicked: ${id}, encoded: ${encodedId}`);
    history.push(`/inventarisasi/detail/${encodedId}`);
  };

  // const filterString =
  //   debFilter !== ""
  //     ? `where: { ${filterCriteria}: { eq: "${debFilter}" } }`
  //     : "";
  const filterString =
    debFilter !== "" ? `&${filterCriteria}=${debFilter}` : "";

  // const gqlStr = `
  //   query ListBendas {
  //     bendas(
  //       filter: {
  //         limit: ${recPerPage}
  //         offset: ${offset}
  //         order: "noRegistrasi ASC",
  //         ${filterString}
  //       }
  //     ) {
  //       idBenda
  //       namaBenda
  //       noInventaris
  //       noRegistrasi
  //       dimensiPanjang
  //       dimensiLebar
  //       dimensiTinggi
  //       dimensiTebal
  //       dimensiDiameter
  //     }
  //   }
  // `;
  // console.log(gqlStr);
  // const LIST_BENDAS = gql(gqlStr);
  // const { loading, error, data } = useQuery(LIST_BENDAS);
  // const { loading, data } = useQuery(LIST_BENDAS);

  const [{ data, loading, error }, refetch] = useAxiosWithToken(
    `/api/inventarisasi?limit=${recPerPage}&offset=${offset}${filterString}`
  );

  const screenHeight = useWindowHeight();
  // const height = size === "computer" ? screenHeight - 250 : screenHeight - 320;
  const height = size === "computer" ? screenHeight - 228 : screenHeight - 300;
  // if (loading) return <Tab.Pane>anu</Tab.Pane>;
  // if (error) return <p>Error :</p>;
  // console.log(error);
  // console.log(data);
  // console.log(size);
  const history = useHistory();

  if (!data) {
    return false;
  }

  return (
    <Tab.Pane>
      <Menu borderless secondary style={{ paddingLeft: 5 }}>
        <DropdownFilter onChange={handleChangeFilterCriteria} />
        <Input
          icon="search"
          placeholder="Cari.."
          onChange={handleChangeFilter}
        />
        <Menu.Menu position="right">
          <Menu.Item onClick={handlePrev}>
            <Icon name="angle left" />
          </Menu.Item>
          <Menu.Item>{offset / recPerPage + 1}</Menu.Item>
          <Menu.Item onClick={handleNext}>
            <Icon name="angle right" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {/* <Segment style={{ padding: 0 }}> */}
      {/* <Segment style={{ padding: 0, overflow: "auto", minHeight: height, maxHeight: height }}> */}
      <Segment style={{ padding: 0, overflow: "auto", maxHeight: height }}>
        {/* <div>Filter Text: {filter} | </div>
        <div>Debounced Filter Text: {debFilter}</div> */}
        <Table selectable striped style={{ borderWidth: 0 }} unstackable>
          <Table.Header>
            {size !== "mobile" ? (
              <Table.Row>
                <Table.HeaderCell width={2}>No.Reg</Table.HeaderCell>
                <Table.HeaderCell width={3}>No.Inv</Table.HeaderCell>
                <Table.HeaderCell width={5}>Nama</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  P
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  L
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  T
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  TB
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  D
                </Table.HeaderCell>
              </Table.Row>
            ) : (
              <Table.Row>
                <Table.HeaderCell width={2}>No.Reg</Table.HeaderCell>
                <Table.HeaderCell width={3}>No.Inv</Table.HeaderCell>
                <Table.HeaderCell width={5}>Nama</Table.HeaderCell>
              </Table.Row>
            )}
          </Table.Header>
          {!loading ? (
            <Table.Body>
              {data.map((value, key) =>
                size !== "mobile" ? (
                  <Table.Row
                    key={key}
                    onClick={() => {
                      handleShowDetail(value.id_benda);
                    }}
                  >
                    <Table.Cell>{value.no_registrasi}</Table.Cell>
                    <Table.Cell>{value.no_inventaris}</Table.Cell>
                    <Table.Cell>{value.nama_benda}</Table.Cell>
                    <Table.Cell textAlign="right">
                      {value.dimensi_panjang}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {value.dimensi_lebar}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {value.dimensi_tinggi}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {value.dimensi_tebal}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {value.dimensi_diameter}
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <Table.Row>
                    <Table.Cell>{value.no_registrasi}</Table.Cell>
                    <Table.Cell>{value.no_inventaris}</Table.Cell>
                    <Table.Cell>{value.nama_benda}</Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          ) : (
            <Table.Body>
              {size !== "mobile" ? (
                <Table.Row>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                </Table.Row>
              ) : (
                <Table.Row>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          )}
        </Table>
      </Segment>
    </Tab.Pane>
  );
};
const Content = (props) => {
  const panes = [
    { menuItem: "Sinkronisasi", render: () => <Migrasi /> },
    { menuItem: "Data Inventaris", render: () => <Data {...props} /> },
  ];
  return (
    <Container style={{ padding: 10, width: "100%" }}>
      {/* <Subheader label="SEKILAS DATA" /> */}
      <Tab panes={panes} defaultActiveIndex={1} />
    </Container>
  );
};

const Inventarisasi = () => {
  const height = useWindowHeight();

  return (
    <div>
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <MediaContextProvider>
        <Media between={["mobile", "tablet"]}>
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height - 70,
              maxHeight: height - 80,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/koleksi_museum_nasional_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <Content size="mobile" />
          </div>
        </Media>
        <Media at="tablet">
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height - 70,
              maxHeight: height - 80,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/koleksi_museum_nasional_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <Content size="tablet" />
          </div>
        </Media>
        <Media greaterThanOrEqual="computer">
          {/* <Grid columns={2} style={{ height: "90vh" }}> */}
          <Grid columns={2}>
            <Grid.Row stretched>
              <Grid.Column
                stretched
                width={6}
                // textAlign="center"
                verticalAlign="middle"
                style={{
                  height: "100%",
                  backgroundImage: `url(${"images/koleksi_museum_nasional_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  // marginTop: 15,
                }}
              >
                <Grid stretched>
                  <Grid.Column stretched verticalAlign="middle">
                    <Head />
                    {/* <Breadcrumb icon="right angle" sections={sections} style={{ marginLeft: 20 }} />
              <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
                Aplikasi Database Koleksi
              </Header> */}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column
                width={10}
                style={{
                  backgroundColor: "#eee",
                  minHeight: height - 87,
                  maxHeight: height - 87,
                }}
              >
                <Content size="computer" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
    </div>
  );
};

export default Inventarisasi;
