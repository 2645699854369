import React, { useState } from "react";
import PlaceholderPara from "components/elements/PlaceholderPara";
import { useWindowHeight } from "@react-hook/window-size";
import { useAxiosWithToken } from "utils/AxiosWithToken";
import ProfileForm from "components/auth/ProfileForm";
import {
  Breadcrumb,
  Container,
  Divider,
  Grid,
  Header,
  Progress,
  Button,
  Icon,
  Segment,
  Tab,
  Table,
  Message,
  Menu,
  Image,
} from "semantic-ui-react";

import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import { del, editForm } from "store/actions/data";
import { useDispatch, useSelector } from "react-redux";
import EditDeletePopup from "components/elements/EditDeletePopup";

const Pengguna = () => {
  // Redux shit
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);

  // Paging shit
  const recPerPage = 6;
  const [offset, setOffset] = useState(0);
  const handleNext = () => {
    setOffset(offset + recPerPage);
  };
  const handlePrev = () => {
    setOffset(offset > 0 ? offset - recPerPage : 0);
  };
  const handleRefresh = () => {
    refetch();
  };

  var url = `/api/users`;
  const [{ data, loading, error }, refetch] = useAxiosWithToken(url);

  const height = useWindowHeight();
  const handleBuatPengguna = () => {
    setUserProfile({
      id: "",
      username: "",
      email: "",
      password: "",
      realm: "",
      picture: "",
    });
    setShowProfile(true);
  };

  // Modal management
  const [showProfile, setShowProfile] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  // const handleShowProfile = (userProfile) => {
  //   console.log(userProfile);
  //   setUserProfile(userProfile);
  //   setShowProfile(true);
  // };

  const deleteHandler = (id) => {
    dispatch(del("user", { id: id }, auth, onFinish));
  };

  const editHandler = (id, data) => {
    // dispatch(editForm("user", { id_user: id }, data));
    console.log(data);
    setUserProfile(data);
    setShowProfile(true);
  };

  const onFinish = (status, message) => {
    if (status) {
      callToast("Success", "success", "Perubahan berhasil disimpan");
    } else {
      callToast("Error", "error", message);
    }
    handleRefresh();
  };

  const callToast = (title, type, message) => {
    toast(
      {
        title: title,
        type: type,
        description: `${message}`,
      },
      () => console.log("toast closed"),
      () => console.log("toast clicked"),
      () => console.log("toast dismissed")
    );
  };

  return (
    <Tab.Pane>
      <Menu borderless secondary style={{ paddingLeft: 5 }}>
        <Button
          icon
          labelPosition="left"
          color="brown"
          onClick={handleBuatPengguna}
        >
          <Icon name="add" />
          Tambah Pengguna
        </Button>
        <Menu.Menu position="right">
          <Menu.Item onClick={handlePrev}>
            <Icon name="angle left" />
          </Menu.Item>
          {/* <Menu.Item onClick={handleRefresh}>
        <Icon name="refresh" />
      </Menu.Item> */}
          <Menu.Item>{offset / recPerPage + 1}</Menu.Item>
          <Menu.Item onClick={handleNext}>
            <Icon name="angle right" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {/* <Form.Field inline>
  {/* <Input icon="search" placeholder="Cari.." /> */}
      {/* </Form.Field> */}
      <Segment style={{ padding: 0, overflow: "auto", maxHeight: height }}>
        {error ? <Message>There is an error: {error}</Message> : ""}
        <Table
          selectable
          structured
          // celled
          striped
          style={{ borderWidth: 0 }}
          unstackable
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>Nama</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Peran</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {!loading && data ? (
            <Table.Body>
              {data.map((value, key) => (
                <Table.Row
                  key={key}
                  onClick={() => {
                    // handleShowProfile(value);
                  }}
                >
                  <Table.Cell>{key + 1}</Table.Cell>
                  <Table.Cell>{value.username}</Table.Cell>
                  <Table.Cell>{value.email}</Table.Cell>
                  <Table.Cell>{value.realm}</Table.Cell>
                  <Table.Cell>
                    <EditDeletePopup
                      trigger={<Icon name="ellipsis vertical" />}
                      delete={() => {
                        deleteHandler(value.id);
                      }}
                      edit={() => {
                        editHandler(value.id, value);
                      }}
                      id={value.id_observasi}
                      data={value}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Segment>

      <ProfileForm
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        userProfile={userProfile}
        handleRefresh={handleRefresh}
        onFinish={onFinish}
        toast={toast}
        title="Tambah/Ubah Pengguna"
      />
      <SemanticToastContainer />
    </Tab.Pane>
  );
};

export default Pengguna;
