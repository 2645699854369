import React from "react";
import {
  Breadcrumb,
  Container,
  Divider,
  Grid,
  Header,
  Segment,
  Table,
} from "semantic-ui-react";
import { MediaContextProvider, Media } from "utils/MediaUtils";
import StatNumber from "components/elements/StatNumber";
import Subheader from "components/elements/Subheader";
import { useWindowHeight } from "@react-hook/window-size";

const ResumeObservasi = () => {
  return (
    <Segment style={{ padding: 0 }}>
      <Table striped style={{ borderWidth: 0 }} unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>No</Table.HeaderCell>
            <Table.HeaderCell width={4} textAlign="left">
              Nama
            </Table.HeaderCell>
            <Table.HeaderCell width={6} textAlign="right">
              Penugasan
            </Table.HeaderCell>
            <Table.HeaderCell width={6} textAlign="right">
              Selesai
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>Ahmad</Table.Cell>
            <Table.Cell textAlign="right">150</Table.Cell>
            <Table.Cell textAlign="right">80</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>2</Table.Cell>
            <Table.Cell>Rudi</Table.Cell>
            <Table.Cell textAlign="right">20</Table.Cell>
            <Table.Cell textAlign="right">10</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3</Table.Cell>
            <Table.Cell>Aldi</Table.Cell>
            <Table.Cell textAlign="right">320</Table.Cell>
            <Table.Cell textAlign="right">30</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>4</Table.Cell>
            <Table.Cell>Wati</Table.Cell>
            <Table.Cell textAlign="right">180</Table.Cell>
            <Table.Cell textAlign="right">150</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>5</Table.Cell>
            <Table.Cell>Lidya</Table.Cell>
            <Table.Cell textAlign="right">15</Table.Cell>
            <Table.Cell textAlign="right">13</Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Jumlah</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">493</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">300</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

const ResumeKondisiKoleksi = () => {
  return (
    <Segment style={{ padding: 0 }}>
      <Table striped style={{ borderWidth: 0 }} unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>No</Table.HeaderCell>
            <Table.HeaderCell width={4} textAlign="left">
              Kategori
            </Table.HeaderCell>
            <Table.HeaderCell width={6} textAlign="right">
              Jumlah
            </Table.HeaderCell>
            <Table.HeaderCell width={6} textAlign="right">
              Rusak
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>Arkeologi</Table.Cell>
            <Table.Cell textAlign="right">13.681</Table.Cell>
            <Table.Cell textAlign="right">80</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>2</Table.Cell>
            <Table.Cell>Etnografi</Table.Cell>
            <Table.Cell textAlign="right">38.648</Table.Cell>
            <Table.Cell textAlign="right">10</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3</Table.Cell>
            <Table.Cell>Keramik</Table.Cell>
            <Table.Cell textAlign="right">31.320</Table.Cell>
            <Table.Cell textAlign="right">30</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>4</Table.Cell>
            <Table.Cell>Numismatik</Table.Cell>
            <Table.Cell textAlign="right">52.475</Table.Cell>
            <Table.Cell textAlign="right">150</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>5</Table.Cell>
            <Table.Cell>Prasejarah</Table.Cell>
            <Table.Cell textAlign="right">45.282</Table.Cell>
            <Table.Cell textAlign="right">13</Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Jumlah</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">162.922</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">300</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

const Head = (props) => {
  const sections = [{ key: "beranda", content: "Beranda", link: true }];
  return (
    <div>
      <Breadcrumb
        icon="right angle"
        sections={sections}
        style={{ marginLeft: 20 }}
      />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        Aplikasi Database Koleksi
      </Header>
    </div>
  );
};

const Content = () => {
  // const height = useWindowHeight();

  return (
    <Container style={{ padding: 10 }}>
      <Subheader label="SEKILAS DATA" />
      <Grid stackable columns={4}>
        <Grid.Row>
          <Grid.Column width={4} style={{ minWidth: "155px" }}>
            <StatNumber
              icon="diamond"
              color="#ceaefa"
              number={162922}
              label="Koleksi"
            />
          </Grid.Column>
          <Grid.Column width={4} style={{ minWidth: "155px" }}>
            <StatNumber
              icon="thumbs up"
              color="#bfdbae"
              number={112212}
              label="Kondisi Baik"
            />
          </Grid.Column>
          <Grid.Column width={4} style={{ minWidth: "155px" }}>
            <StatNumber
              icon="thumbs down"
              color="#ffcfdc"
              number={50709}
              label="Kondisi Rusak"
            />
          </Grid.Column>
          <Grid.Column width={4} style={{ minWidth: "155px" }}>
            <StatNumber
              icon="eye"
              color="#bdf6fe"
              number={210}
              label="Terobservasi"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden />
      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Subheader label="RESUME OBSERVASI" />
            <ResumeObservasi />
          </Grid.Column>
          <Grid.Column>
            <Subheader label="RESUME KONDISI" />
            <ResumeKondisiKoleksi />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

const Dashboard = () => {
  const height = useWindowHeight();
  return (
    <div>
      {/* <div style={{ backgroundColor: "#eee", width }}/> */}
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <MediaContextProvider>
        <Media between={["mobile", "tablet"]}>
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height - 70,
              maxHeight: height - 80,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/foto_museum_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <div style={{ overflow: "scroll", maxHeight: height - 100 }}>
              <Content />
            </div>
          </div>
        </Media>
        <Media at="tablet">
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height,
              maxHeight: height,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/foto_museum_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <Content />
          </div>
        </Media>
        <Media greaterThanOrEqual="computer">
          <Grid columns={2} style={{ height: "90vh" }}>
            <Grid.Row stretched>
              <Grid.Column
                stretched
                width={6}
                // textAlign="center"
                verticalAlign="middle"
                style={{
                  height: "100%",
                  backgroundImage: `url(${"images/foto_museum_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  // marginTop: 15,
                }}
              >
                <Grid stretched>
                  <Grid.Column stretched verticalAlign="middle">
                    <Head />
                    {/* <Breadcrumb icon="right angle" sections={sections} style={{ marginLeft: 20 }} />
                    <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
                      Aplikasi Database Koleksi
                    </Header> */}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column width={10} style={{ backgroundColor: "#eee" }}>
                <Content />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
      {/* <Header as="h3">Dashboard</Header>
      <Image src="/images/paragraph.png" /> */}
    </div>
  );
};
export default Dashboard;
