import * as ui from "../actions/ui";

const INITIAL_STATE = {
  sideBarMenuVisible: false,
  sideBarFormVisible: false,
  bottomCartVisible: false,
  activeMenu: "beranda",
  activeTabLaporan: 0,
  activeTabObservasi: 0,
};

const uiReducer = (state = INITIAL_STATE, action) => {
  console.log(action);
  switch (action.type) {
    case ui.TOGGLE_SIDEBARMENU:
      return {
        ...state,
        sideBarMenuVisible: !state.sideBarMenuVisible,
      };
    case ui.TOGGLE_SIDEBARFORM:
      return {
        ...state,
        sideBarFormVisible: action.sideBarFormVisible,
      };
    case ui.TOGGLE_BOTTOMCART:
      return {
        ...state,
        bottomCartVisible: action.bottomCartVisible,
      };

    case ui.TOGGLE_ACTIVEMENU:
      return {
        ...state,
        activeMenu: action.activeMenu,
      };
    case ui.TOGGLE_ACTIVETAB_LAPORAN:
      return {
        ...state,
        activeTabLaporan: action.activeTabLaporan,
      };
    case ui.TOGGLE_ACTIVETAB_OBSERVASI:
      return {
        ...state,
        activeTabObservasi: action.activeTabObservasi,
      };

    default:
      return state;
  }
};

export default uiReducer;
