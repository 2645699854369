import axios from "axios";
import { makeUseAxios } from "axios-hooks";
import { store, history } from "../";

import { logout } from "store/actions/auth";
// import env from "react-dotenv";
import { API_URL } from "env";

const axiosWithToken = axios.create({
  baseURL: API_URL,
});

axiosWithToken.interceptors.request.use(
  async (config) => {
    const { id, token } = store.getState().authReducer;
    console.log(id, token);

    // config.params = { ...config.params, user_id: id };
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

axiosWithToken.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (error.response.status === 401) {
        console.log("You are not authorized");
        // history.push("/login");
        // store.dispatch(logout());
        store.dispatch(logout());
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      }
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

const useAxiosWithToken = makeUseAxios({
  axios: axiosWithToken,
});

export { axiosWithToken, useAxiosWithToken };
