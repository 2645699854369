import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
const isEmpty = (text) => {
  return text.length === 0;
};

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useSelector((state) => state.authReducer);
  const { token } = auth;
  if (!isEmpty(token)) console.log("welcome");
  else console.log("sorry, redirecting...");

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isEmpty(token) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
