import React from "react";
import { useWindowHeight } from "@react-hook/window-size";
import { Icon, Segment, Tab, Table } from "semantic-ui-react";

const Unduhan = () => {
  const height = useWindowHeight();
  return (
    <Tab.Pane>
      {/* <Form.Field inline>
  {/* <Input icon="search" placeholder="Cari.." /> */}
      {/* </Form.Field> */}
      <Segment style={{ padding: 0, overflow: "auto", maxHeight: height }}>
        {/* {error ? <Message>There is an error: {error}</Message> : ""} */}
        <Table selectable structured celled striped style={{ borderWidth: 0 }} unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>No.</Table.HeaderCell>
              <Table.HeaderCell width={3}>Nama UnduhanView</Table.HeaderCell>
              <Table.HeaderCell width={2}>Jenis File</Table.HeaderCell>
              <Table.HeaderCell width={2}>Ukuran</Table.HeaderCell>
              <Table.HeaderCell width={5}>Keterangan</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>1</Table.Cell>
              <Table.Cell>Dokumen Manual</Table.Cell>
              <Table.Cell>
                <Icon name="file pdf" />
                PDF
              </Table.Cell>
              <Table.Cell>126KB</Table.Cell>
              <Table.Cell>Dokumen unduhan ini memuat panduan cara menggunakan aplikasi.</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>2</Table.Cell>
              <Table.Cell>Prosedur Observasi</Table.Cell>
              <Table.Cell>
                <Icon name="file pdf" />
                PDF
              </Table.Cell>
              <Table.Cell>202KB</Table.Cell>
              <Table.Cell>Dokumen unduhan ini merupakan acuan Prosedur Pelaksanaan Observasi Koleksi Museum Nasional.</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </Tab.Pane>
  );
};

export default Unduhan;
