import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Divider,
  // Dropdown,
  Form,
  // Grid,
  // GridColumn,
  Header,
  Icon,
  Input,
  // Input,
  Menu,
  Popup,
  TextArea,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { toggleSidebarForm } from "store/actions/ui";
import { post, put, resetEditForm, toggleIsLoading } from "store/actions/data";
import moment from "moment";
import { generateId } from "utils/Lib";
import DropdownLokasiObservasi from "components/dropdowns/DropdownLokasiObservasi";
import DropdownPic from "components/dropdowns/DropdownPic";

const FormObservasi = (props) => {
  const toast = props.toast;
  const callToast = props.callToast;

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authReducer);
  const data = useSelector((state) => state.dataReducer);
  const ui = useSelector((state) => state.uiReducer);

  const { editModel, editParam, editData } = data;
  const { sideBarFormVisible } = ui;

  // const { isLoading } = useSelector((state) => state.dataReducer);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (status, result) => {
    setIsLoading(false);
    console.log(result);
    if (status) {
      // showInfoToast("Rencana Observasi berhasil disimpan");
      callToast("Success", "success", "Data berhasil disimpan");
      dispatch(resetEditForm());
    } else {
      // showErrorToast(result.replace(/(<([^>]+)>)/gi, ""));
      callToast("Error", "error", result);
    }
  };

  // Sending to backend
  const handleSave = () => {
    // Convert tanggal to string
    // let idObservasi = generateId("T", "==", 21);
    let tanggal_mulai = moment(formValues.tanggal_mulai).format(
      "YYYY/MM/DD HH:mm:ss"
    );
    let tanggal_selesai = moment(formValues.tanggal_selesai).format(
      "YYYY/MM/DD HH:mm:ss"
    );
    let output = { ...formValues, tanggal_mulai, tanggal_selesai };
    console.log(output);

    // Call redux action
    // dispatch(toggleIsLoading(true));
    setIsLoading(true);
    if (editModel === "observasi") {
      // PUT
      dispatch(put("observasi", output, editParam, auth, onFinish));
    } else {
      // POST
      dispatch(post("observasi", output, auth, onFinish));
    }
  };

  // This triggered by new buat
  useEffect(() => {
    setFormValues(initialState);
  }, [sideBarFormVisible]);

  // This triggered by stop loading (save success)
  useEffect(() => {
    if (isLoading === false) {
      dispatch(toggleSidebarForm(false));
    }
  }, [isLoading]);

  // This triggered by edit
  useEffect(() => {
    // console.log(`Edit state is changed: ${editModel}`);
    // console.log(editModel);
    // console.log(editParam);
    // console.log(editData);
    if (editModel === "observasi") {
      setFormValues({
        ...editData,
        tanggal_mulai: moment(editData.tanggal_mulai).toDate(),
        tanggal_selesai: moment(editData.tanggal_selesai).toDate(),
      });
    }
  }, [editModel, editParam, editData]);

  const handleCancel = () => {
    dispatch(toggleSidebarForm(false));
  };

  const handleDummy = () => {
    setFormValues({
      judul_observasi: "Observasi koleksi Pra Sejarah 1",
      tanggal_mulai: moment().add(1, "days").toDate(),
      tanggal_selesai: moment().add(4, "days").toDate(),
      id_lokasi_observasi: 10,
      id_pics: [
        "0cee060e-41ef-4cf8-9b8a-49e8b1ed19d3",
        "3d63d8da-7273-4a9a-a440-70ba3982e12a",
      ],
      keterangan: "Observasi koleksi Pra Sejarah Zaman Palaeolithikum",
    });
  };

  const initialState = {
    judul_observasi: "",
    tanggal_mulai: moment().add(1, "days").toDate(),
    tanggal_selesai: moment().add(4, "days").toDate(),
    id_lokasi_observasi: "",
    id_pics: [],
    keterangan: "",
  };

  const [formValues, setFormValues] = useState(initialState);

  const [formValidationMessage, setFormValidationMessage] = useState({
    judul_observasi: "",
    tanggal_mulai: "",
    tanggal_selesai: "",
    id_lokasi_observasi: "",
    id_pics: "",
    keterangan: "",
  });

  const [formValidity, setFormValidity] = useState({
    judul_observasi: true,
    tanggal_mulai: true,
    tanggal_selesai: true,
    id_lokasi_observasi: true,
    id_pics: true,
    keterangan: true,
  });

  // Handle change for non ordinary text input
  const handleChange = (name, value) => {
    console.log(name, value);

    setFormValues({
      ...formValues,
      [name]: value,
    });
    setTimeout(() => {
      console.log(formValues);
    }, 2000);
  };

  // Handle validate for non ordinary text input
  const handleValidate = (name) => {
    var isValid = true;
    var message = "";
    switch (name) {
      default:
        isValid = formValues[name] !== "";
        message = name + " tidak boleh kosong";
        break;
    }
    setFormValidity({
      ...formValidity,
      [name]: isValid,
    });
    if (!isValid) {
      setFormValidationMessage({
        ...formValidationMessage,
        [name]: message,
      });
    }
  };

  // Handle change for ordinary text input
  const change = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    console.log(formValues);
  };

  // Handle validate for ordinary text input
  const validate = (e) => {
    var isValid = true;
    var message = "";
    switch (e.target.name) {
      default:
        isValid = e.target.value !== "";
        message = e.target.name + " tidak boleh kosong";
        break;
    }
    setFormValidity({
      ...formValidity,
      [e.target.name]: isValid,
    });
    if (!isValid) {
      setFormValidationMessage({
        ...formValidationMessage,
        [e.target.name]: message,
      });
    }
  };

  // const checkValid = () => {
  //   console.log();
  //   var vals = Object.keys(formValues).map((key) => formValues[key]);
  //   return vals.reduce((accValue, currValue) => accValue && currValue);
  // };

  // const options = [
  //   {
  //     key: "1",
  //     value: "1",
  //     text: "Konservator",
  //   },
  //   {
  //     key: "2",
  //     value: "2",
  //     text: "Pesanan Pejabat",
  //   },
  //   {
  //     key: "3",
  //     value: "3",
  //     text: "Bidang Konservasi",
  //   },
  //   {
  //     key: "4",
  //     value: "4",
  //     text: "Bidang Registrasi",
  //   },
  // ];

  return (
    <Container>
      <Menu secondary borderless>
        <Header
          as="h3"
          style={{ paddingTop: 20, paddingLeft: 20, marginBottom: 0 }}
        >
          Form Rencana Observasi
        </Header>
        <Menu.Menu position="right">
          <Menu.Item position="right" active={false} onClick={handleCancel}>
            <Icon name="close" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Divider />
      <Form style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Form.Field>
          <label>Judul Observasi</label>
          <Popup
            position="bottom right"
            open={!formValidity.judul_observasi}
            content={formValidationMessage.judul_observasi}
            trigger={
              <Input
                // fluid
                name="judul_observasi"
                // error={!formValidity.judul_observasi}
                value={formValues.judul_observasi}
                onChange={change}
                onBlur={validate}
                placeholder="Judul Observasi"
              />
            }
          />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <SemanticDatepicker
              icon="calendar"
              label="Tanggal Mulai"
              name="tanggal_mulai"
              format="DD/MM/YYYY"
              // error={!formValidity.tanggal_mulai}
              value={formValues.tanggal_mulai}
              // onChange={handleChange}
              onChange={(e, f) => handleChange(f.name, f.value)}
              onBlur={() => handleValidate("tanggal_mulai")}
              placeholder="Pilih tanggal..."
            />
          </Form.Field>
          <Form.Field>
            <SemanticDatepicker
              icon="calendar"
              label="Tanggal Selesai"
              name="tanggal_selesai"
              format="DD/MM/YYYY"
              // error={!formValidity.tanggal_selesai}
              value={formValues.tanggal_selesai}
              onChange={(e, f) => handleChange(f.name, f.value)}
              onBlur={() => handleValidate("tanggalSelesai")}
              placeholder="Pilih tanggal..."
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Lokasi Observasi</label>
            <Popup
              position="bottom right"
              open={!formValidity.id_lokasi_observasi}
              content={formValidationMessage.id_lokasi_observasi}
              trigger={
                <DropdownLokasiObservasi
                  name="id_lokasi_observasi"
                  onChange={(v) => handleChange("id_lokasi_observasi", v)}
                  value={formValues.id_lokasi_observasi}
                />
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Daftar PIC</label>
            <Popup
              position="bottom right"
              open={!formValidity.id_pics}
              content={formValidationMessage.id_pics}
              trigger={
                <DropdownPic
                  name="id_pics"
                  onChange={(v) => handleChange("id_pics", v)}
                  value={formValues.id_pics}
                />
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>Keterangan</label>
          <Popup
            position="bottom right"
            open={!formValidity.judul_observasi}
            content={formValidationMessage.keterangan}
            trigger={
              <TextArea
                // fluid
                label="Keterangan"
                name="keterangan"
                // error={!formValidity.keterangan}
                value={formValues.keterangan}
                onChange={change}
                onBlur={validate}
                placeholder="Keterangan"
              />
            }
          />
        </Form.Field>

        <Button type="submit" primary onClick={handleSave} loading={isLoading}>
          Simpan
        </Button>
        <Button type="cancel" onClick={handleCancel}>
          Batal
        </Button>
        <Button
          type="cancel"
          onClick={handleDummy}
          style={{
            float: "right",
          }}
        >
          Dummy
        </Button>
      </Form>
    </Container>
  );
};

export default FormObservasi;
