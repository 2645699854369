import React from "react";
import { useSelector } from "react-redux";
import { Menu, Sidebar } from "semantic-ui-react";

const SidebarForm = (props) => {
  const { sideBarFormVisible } = useSelector((state) => state.uiReducer);
  // const dispatch = useDispatch();
  // let width = props.size === "computer" ? "60%" : "100%";
  const width = props.size === "mobile" ? "wide" : "very wide";
  const style = props.size === "mobile" ? { wide: "very wide" } : {};
  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      // onHide={() => dispatch(toggleSidebar())}
      visible={sideBarFormVisible}
      direction="right"
      width={width}
      vertical
      style={style}
    >
      {props.children}
    </Sidebar>
  );
};

export default SidebarForm;
