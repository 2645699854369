import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";

const DropdownKlasifikasiKerusakan = (props) => {
  const options = [
    {
      key: 1,
      value: 1,
      text: "A - Tinggi",
    },
    {
      key: 2,
      value: 2,
      text: "B - Sedang",
    },
    {
      key: 3,
      value: 3,
      text: "C - Ringan",
    },
  ];

  return (
    <Dropdown
      selection
      {...props}
      options={options}
      onChange={(e, f) => {
        // handleChange(f.value);
        console.log("Value changed!!", f);
        props.onChange(f.value);
      }}
    />
  );
};

export default DropdownKlasifikasiKerusakan;
