import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Grid,
  Header,
  Icon,
  Menu,
  Segment,
  Sidebar,
} from "semantic-ui-react";
import DropdownObservasi from "components/dropdowns/DropdownObservasi";
import { toggleBottomCart } from "store/actions/ui";
import {
  deleteCartObservasi,
  post,
  toggleIdObservasi,
} from "store/actions/data";
import CartContent from "./CartContent";

const Cart = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);

  const { bottomCartVisible } = useSelector((state) => state.uiReducer);
  const { idObservasi, deskripsiObservasi, cartObservasi } = useSelector(
    (state) => state.dataReducer
  );

  // const [id_observasi, setIdObservasi] = useState("");
  // const [showDDObservasi, setShowDDObservasi] = useState(false);

  const handleCancel = () => {
    dispatch(toggleBottomCart(false));
  };

  // const onFinish = (data) => {
  //   dispatch(deleteCartObservasi(data.id_benda));
  // };

  const onFinish = (status, result, data) => {
    if (status) {
      // showInfoToast("Rencana Observasi berhasil disimpan");
      // callToast("Success", "success", "Data berhasil disimpan");
      dispatch(deleteCartObservasi(data.id_benda));
    } else {
      // showErrorToast(result.replace(/(<([^>]+)>)/gi, ""));
      // callToast("Error", "error", result);
    }
  };

  const handleSave = () => {
    const data = cartObservasi.map((val, key) => {
      var output = {
        id_observasi: idObservasi,
        is_organik: false,
        is_anorganik: false,
        lingkungan_mikro: false,
        id_benda: val.id_benda,
        dimensi_tinggi: val.dimensi_tinggi,
        id_satuan_tinggi: val.id_satuan_tinggi,
        dimensi_panjang: val.dimensi_panjang,
        id_satuan_panjang: val.id_satuan_panjang,
        dimensi_lebar: val.dimensi_lebar,
        id_satuan_lebar: val.id_satuan_lebar,
        dimensi_diameter: val.dimensi_diameter,
        id_satuan_diameter: val.id_satuan_diameter,
        dimensi_tebal: val.dimensi_tebal,
        id_satuan_tebal: val.id_satuan_tebal,
        berat: val.berat,
        id_satuan_berat: val.id_satuan_berat,
      };
      dispatch(post("observasi_rinci", output, auth, onFinish));
    });
  };

  const handleChangeIdObservasi = (v, t) => {
    dispatch(toggleIdObservasi(v, t));
  };
  const [openCart, setOpenCart] = useState(false);
  const handleOpenCart = () => {
    setOpenCart(true);
  };

  return (
    <Sidebar
      as={Segment}
      direction="bottom"
      animation="push"
      visible={bottomCartVisible}
      style={{ backgroundColor: "#a5673f" }}
    >
      <Grid columns={3}>
        <Grid.Column floated="left" width={8}>
          <Form inverted>
            <Form.Field>
              <label>Pilih Rencana Observasi</label>
              <DropdownObservasi
                onChange={handleChangeIdObservasi}
                value={idObservasi}
                text={deskripsiObservasi}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column
          floated="right"
          textAlign="right"
          verticalAlign="middle"
          width={6}
        >
          <CartContent
            idObservasi={idObservasi}
            deskripsiObservasi={deskripsiObservasi}
            cartObservasi={cartObservasi}
            handleSave={handleSave}
            open={openCart}
          >
            <Header inverted as="h2">
              <Icon inverted name="tasks" color="grey" />
              {cartObservasi.length}
            </Header>
          </CartContent>
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right" width={2}>
          {/* <Icon name="close" /> */}
          <Menu.Item position="right" onClick={handleCancel}>
            <Icon inverted name="close" />
          </Menu.Item>
        </Grid.Column>
      </Grid>
    </Sidebar>
  );
};

export default Cart;
