import React from "react";

const Subheader = ({ label }) => {
  return <div style={styles.subheader}>{label}</div>;
};
const styles = {
  subheader: {
    fontWeight: "bold",
    color: "#999",
    marginBottom: 12,
  },
};

export default Subheader;
