import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { toggleSidebarForm } from "store/actions/ui";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import {
  Button,
  Icon,
  Input,
  Segment,
  Tab,
  Table,
  Menu,
  Dropdown,
  Image,
  Header,
} from "semantic-ui-react";
import PlaceholderPara from "components/elements/PlaceholderPara";
import { useWindowHeight } from "@react-hook/window-size";
// import {
//   BoxDesktop,
//   BoxTablet,
//   BoxMobile,
//   BoxDefault,
// } from "components/elements/Responsive";
import DropdownFilter from "components/dropdowns/DropdownFilter";
// import DropdownAsalPermintaan from "components/dropdowns/DropdownAsalPermintaan";
import useScreenSize from "utils/ScreenSize";
import { toggleBottomCart } from "store/actions/ui";
import {
  EAPI_URL,
  insertCartObservasi,
  toggleIdObservasiRinci,
} from "store/actions/data";
import DropdownObservasi from "components/dropdowns/DropdownObservasi";

import { post, toggleIdObservasi } from "store/actions/data";
import { useAxiosWithToken } from "utils/AxiosWithToken";
import moment from "moment";
import { useHistory } from "react-router-dom";

const PelaksanaanObservasi = (props) => {
  // UI horizontal setting from parent
  // const { size } = props;
  const size = useScreenSize();

  // UI vertical setting
  const screenHeight = useWindowHeight();
  // const topBarHeightComputer = 248;
  // const topBarHeightOther = 320;
  // const topBarHeightComputer = 348;
  // const topBarHeightOther = 420;
  // const topBarHeightComputer = 150;
  const topBarHeightComputer = 150;
  const topBarHeightTablet = 230;
  const topBarHeightMobile = 230;

  var theHeight = 0;
  var segHeight = 0;
  var pageSize = 0;
  var buttonBottom = 0;
  var buttonRight = 0;

  switch (size) {
    case "computer":
      theHeight = screenHeight - topBarHeightComputer;
      segHeight = theHeight - 100;
      pageSize = 20;
      buttonBottom = 40;
      buttonRight = 30;
      break;
    case "tablet":
      theHeight = screenHeight - topBarHeightTablet;
      segHeight = theHeight - 100;
      pageSize = 15;
      buttonBottom = 40;
      buttonRight = 30;
      break;
    case "mobile":
      theHeight = screenHeight - topBarHeightMobile;
      segHeight = theHeight - 85;
      pageSize = 6;
      buttonBottom = 5;
      buttonRight = 0;
      break;
  }
  const height = theHeight;
  const segmentHeight = segHeight;

  // console.log("-------------------");
  // console.log("Screen Size: ", size);
  // console.log("Screen Height: ", screenHeight);
  // console.log("Calculated height", height);

  // Mapping shit
  // const [idObservasi, setIdObservasi] = useState("");
  // const [showDDObservasi, setShowDDObservasi] = useState(false);

  const dispatch = useDispatch();
  const handleBuatDaftar = () => {
    // setShowDDObservasi(true);
    dispatch(toggleBottomCart(true));
  };

  const handleCancel = () => {
    // setShowDDObservasi(false);
  };

  const handleOpenForm = (id, value, desc) => {
    // console.log("Opening form: ", id, value, desc);
    dispatch(toggleSidebarForm(true));
    dispatch(toggleIdObservasiRinci(id, value, desc));
    // console.log("Clicked: ", id);
  };

  const history = useHistory();
  const handleShowDetail = (id) => {
    // console.log("Clicked: ", id);
    history.push(`/laporan_observasi/detail/${id}`);
  };

  const { bottomCartVisible } = useSelector((state) => state.uiReducer);
  // const { cartObservasi, idObservasi } = useSelector((state) => state.dataReducer);
  const {
    idObservasi,
    obyekObservasiRinci,
    deskripsiObservasi,
    idObservasiRinci,
  } = useSelector((state) => state.dataReducer);

  //////////// DATA STUFF //////////////

  // Paging shit
  const recPerPage = pageSize;
  const [offset, setOffset] = useState(0);

  // Paging button handler
  const handleNext = () => {
    setOffset(offset + recPerPage);
  };
  const handlePrev = () => {
    setOffset(offset > 0 ? offset - recPerPage : 0);
  };
  const handleRefresh = () => {
    refetch();
  };
  // Filtering Shit
  const [filter, setFilter] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("nama_benda");
  const handleChangeFilterCriteria = (v) => {
    setFilterCriteria(v);
  };
  const handleChangeFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleChangeIdObservasi = (v, t) => {
    dispatch(toggleIdObservasi(v, t));
  };

  const [{ data, loading, error }, refetch] = useAxiosWithToken(
    // `http://localhost:3333/api/observasi-rincis?idObservasi=${idObservasi}`
    `/api/observasi-rincis?idObservasi=${idObservasi}`
  );

  if (data) {
    // console.log(data);
  }

  // const users = usersData ? new Map(usersData.map((x) => [x.id, x])) : [];
  // console.log(users);

  // Filter bassed on dropdown
  // const filterString = `where: { idObservasi: { eq: "${idObservasi}" } }`;

  // Core database handler
  // const listKondisiQuery = `
  //   query ListObservasiRinci {
  //     observasiRincis (
  //       filter: {
  //         limit: ${recPerPage}
  //         offset: ${offset}
  //         # order: "noRegistrasi ASC",
  //         ${filterString}
  //       }
  //     ) {
  //       idObservasiRinci
  //       idObservasi
  //       idBenda
  //       pelaksana1
  //       pelaksana2
  //     }
  //   }
  // `;

  // console.log(listKondisiQuery);
  // const LIST_KONDISI = gql(listKondisiQuery);
  // const { loading, error, data, refetch } = useQuery(LIST_KONDISI);

  // useEffect(() => {
  //   // if (data) console.log(data.observasiRincis);
  //   if (data) {
  //     var listOfBendaId = data.observasiRincis.map((val, key) => {
  //       return val.idBenda;
  //     });
  //     var filter = JSON.stringify({
  //       where: { idBenda: { inq: listOfBendaId } },
  //     });
  //     console.log(filter);
  //   }
  // }, [data]);

  // Users
  // const [{ usersData, usersloading, usersError }, userRefetch] = useAxiosWithToken(
  //   "/users?filter[where][realm]=users"
  // );

  // const users = usersData ? usersData.map((val, key) => {
  //   return
  // })

  return (
    <Tab.Pane style={{ minHeight: height }}>
      <Menu borderless secondary style={{ paddingLeft: 5 }}>
        <DropdownObservasi
          onChange={handleChangeIdObservasi}
          value={idObservasi}
          text={deskripsiObservasi}
        />
        {size === "mobile" ? (
          <Menu.Menu position="right">
            <Menu.Item onClick={handlePrev} fitted="horizontally">
              <Icon name="angle left" />
            </Menu.Item>
            <Menu.Item onClick={handleNext} fitted="horizontally">
              <Icon name="angle right" />
            </Menu.Item>
          </Menu.Menu>
        ) : (
          <Menu.Menu position="right">
            <Menu.Item onClick={handlePrev}>
              <Icon name="angle left" />
            </Menu.Item>
            <Menu.Item onClick={handleNext}>
              <Icon name="angle right" />
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
      {!loading && !data ? (
        <Segment placeholder>
          <Header icon>
            <br />
            <br />
            <Icon name="database" />
            <br />
            Data kosong. <br />
            <br />
            {idObservasi === "" ? (
              <div>
                Pilih salah satu <br />
                Observasi pada pilihan di atas.
              </div>
            ) : (
              <div>Lakukan pemetaan benda terlebih dahulu.</div>
            )}
          </Header>
        </Segment>
      ) : (
        <Segment
          style={{
            padding: 0,
            overflow: "auto",
            // minHeight: 200,
            maxHeight: segmentHeight,
            position: "relative",
          }}
        >
          {size === "mobile" ? (
            <Table striped style={{ borderWidth: 0 }} unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>No</Table.HeaderCell>
                  <Table.HeaderCell width={6}>Benda</Table.HeaderCell>
                  {/* <Table.HeaderCell width={4}>Pelaksana</Table.HeaderCell> */}
                  <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                  <Table.HeaderCell width={1}> </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {!loading && data ? (
                <Table.Body>
                  {/* {data.observasiRincis.map(({ value, key }) => { */}
                  {data.map((value, key) => (
                    <Table.Row key={key}>
                      <Table.Cell>{key + 1}</Table.Cell>
                      <Table.Cell>
                        {value.no_registrasi} / {value.no_inventaris}
                        <br />
                        <b>{value.nama_benda}</b>
                      </Table.Cell>
                      <Table.Cell>
                        {value.nama_pelaksana1}, {value.nama_pelaksana2}
                      </Table.Cell>
                      <Table.Cell>
                        {value.waktu_observasi ? (
                          <div>
                            Terobservasi pada{" "}
                            {moment(value.waktu_observasi).format(
                              "DD/MM/YYYY HH:MM"
                            )}
                          </div>
                        ) : (
                          <div>Belum Observasi {value.waktu_observasi}</div>
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Image.Group>
                          {value.waktu_observasi ? (
                            <Image
                              as="a"
                              src="icons/eye.png"
                              height={20}
                              width={20}
                              onClick={() => {
                                handleShowDetail(value.id_observasi_rinci);
                              }}
                            />
                          ) : (
                            <Image
                              as="a"
                              src="icons/observe.png"
                              height={20}
                              width={20}
                              onClick={() => {
                                // console.log(value);
                                handleOpenForm(
                                  value.id_observasi_rinci,
                                  value,
                                  `${value.no_registrasi} / ${value.no_inventaris} - ${value.nama_benda}`
                                );
                              }}
                            />
                          )}
                        </Image.Group>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              ) : (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <PlaceholderPara />
                    </Table.Cell>
                    <Table.Cell>
                      <PlaceholderPara />
                    </Table.Cell>
                    <Table.Cell>
                      <PlaceholderPara />
                    </Table.Cell>
                    <Table.Cell>
                      <PlaceholderPara />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}
            </Table>
          ) : (
            <Table selectable striped style={{ borderWidth: 0 }} unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>No</Table.HeaderCell>
                  <Table.HeaderCell width={6}>Benda</Table.HeaderCell>
                  {/* <Table.HeaderCell width={4}>Pelaksana</Table.HeaderCell> */}
                  <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                  <Table.HeaderCell width={2}> </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {!loading && data ? (
                <Table.Body>
                  {/* {data.observasiRincis.map(({ value, key }) => { */}
                  {data.map((value, key) => (
                    <Table.Row
                      key={key}
                      onClick={() => {
                        // handleOpenForm(
                        //   value.id_observasi_rinci,
                        //   `${value.no_registrasi} / ${value.no_inventaris} - ${value.nama_benda}`
                        // );
                      }}
                    >
                      <Table.Cell>{key + 1}</Table.Cell>
                      <Table.Cell>
                        {value.no_registrasi} / {value.no_inventaris}
                        <br />
                        <b>{value.nama_benda}</b>
                      </Table.Cell>
                      {/* <Table.Cell>
                        {value.nama_pelaksana1}, {value.nama_pelaksana2}
                      </Table.Cell> */}
                      <Table.Cell>
                        {value.waktu_observasi ? (
                          <div>
                            Selesai{" "}
                            {moment(value.waktu_observasi).format("DD/MM/YYYY")}
                          </div>
                        ) : (
                          <div>Belum Observasi {value.waktu_observasi}</div>
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Image.Group>
                          {value.waktu_observasi ? (
                            <Image
                              as="a"
                              src="icons/eye.png"
                              height={20}
                              width={20}
                              onClick={() => {
                                handleShowDetail(value.id_observasi_rinci);
                              }}
                            />
                          ) : (
                            <Image
                              as="a"
                              src="icons/observe.png"
                              height={20}
                              width={20}
                              onClick={() => {
                                // console.log(value);
                                handleOpenForm(
                                  value.id_observasi_rinci,
                                  value,
                                  `${value.no_registrasi} / ${value.no_inventaris} - ${value.nama_benda}`
                                );
                              }}
                            />
                          )}
                        </Image.Group>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              ) : (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <PlaceholderPara />
                    </Table.Cell>
                    <Table.Cell>
                      <PlaceholderPara />
                    </Table.Cell>
                    <Table.Cell>
                      <PlaceholderPara />
                    </Table.Cell>
                    <Table.Cell>
                      <PlaceholderPara />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}
            </Table>
          )}
        </Segment>
      )}
    </Tab.Pane>
  );
};

export default PelaksanaanObservasi;
