import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MediaContextProvider, Media } from "utils/MediaUtils";
import { useWindowHeight } from "@react-hook/window-size";
import { Breadcrumb, Button, Container, Form, Grid, Header, Icon, Segment, Tab, Table, Sidebar, Message, Menu } from "semantic-ui-react";
import PelaksanaanObservasi from "./laporan/PelaksanaanObservasi";
import FormLaporan from "components/pages/laporan/FormLaporan";
import SidebarForm from "components/elements/SidebarForm";
import LaporanObservasi from "./laporan/LaporanObservasi";
import { toggleActivetabLaporan } from "store/actions/ui";

const Head = (props) => {
  const sections = [
    { key: "beranda", content: "Beranda", link: true },
    { key: "rencana_observasi", content: "Laporan Observasi", link: true },
  ];
  return (
    <div>
      <Breadcrumb icon="right angle" sections={sections} style={{ marginLeft: 20 }} />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        Laporan Observasi
      </Header>
    </div>
  );
};

const Content = (props) => {
  const { activeTabLaporan } = useSelector((state) => state.uiReducer);

  const dispatch = useDispatch();
  const handleTabChange = (e, { activeIndex }) => dispatch(toggleActivetabLaporan(activeIndex));

  const height = useWindowHeight();
  const panes = [
    {
      menuItem: "Pelaksanaan",
      render: () => <PelaksanaanObservasi {...props} />,
    },
    {
      menuItem: "Rekap dan Laporan",
      render: () => <LaporanObservasi {...props} />,
    },
    // { menuItem: "Laporan", render: () => <PenugasanObservasi /> },
  ];
  return (
    <Container style={{ padding: 10, width: "100%" }}>
      {/* <Subheader label="SEKILAS DATA" /> */}
      <Tab panes={panes} activeIndex={activeTabLaporan} onTabChange={handleTabChange} />
    </Container>
  );
};

const Laporan = () => {
  const { sideBarFormVisible } = useSelector((state) => state.uiReducer);

  // const height = useWindowHeight() - 168;
  const height = useWindowHeight();
  console.log(height);
  return (
    <div>
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <Sidebar.Pushable>
        <MediaContextProvider>
          <Media between={["mobile", "tablet"]}>
            <Sidebar.Pushable>
              <SidebarForm size="mobile">
                <FormLaporan />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <div
                  style={{
                    backgroundColor: "#eee",
                    minHeight: height - 70,
                    maxHeight: height - 80,
                  }}
                >
                  <Grid columns={1} style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column
                      style={{
                        height: "20%",
                        backgroundImage: `url(${"images/foto_observasi_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Head />
                    </Grid.Column>
                  </Grid>
                  <Content size="mobile" />
                </div>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
          <Media at="tablet">
            <Sidebar.Pushable>
              <SidebarForm size="computer">
                <FormLaporan />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <div
                  style={{
                    backgroundColor: "#eee",
                    minHeight: height - 70,
                    maxHeight: height - 80,
                  }}
                >
                  <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
                    <Grid.Column
                      style={{
                        height: "20%",
                        backgroundImage: `url(${"images/foto_observasi_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Head />
                    </Grid.Column>
                  </Grid>
                  <Content size="tablet" />
                </div>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
          <Media greaterThanOrEqual="computer">
            <Sidebar.Pushable>
              <SidebarForm size="computer">
                <FormLaporan />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <Grid columns={2}>
                  <Grid.Row stretched>
                    <Grid.Column
                      stretched
                      width={6}
                      verticalAlign="middle"
                      style={{
                        height: "100%",
                        backgroundImage: `url(${"images/foto_observasi_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Grid stretched>
                        <Grid.Column stretched verticalAlign="middle">
                          <Head />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      style={{
                        backgroundColor: "#eee",
                        minHeight: height - 87,
                        maxHeight: height - 87,
                      }}
                    >
                      <Content size="computer" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
        </MediaContextProvider>
      </Sidebar.Pushable>
    </div>
  );
};

export default Laporan;
