import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Sidebar } from "semantic-ui-react";
import TopBar from "./elements/TopBar";
// import Avatar from "./elements/Avatar";
import SideBarMenu from "./elements/SidebarMenu";
import Dashboard from "./pages/Dashboard";
// import { toggleActivemenu, toggleSidebar } from "store/actions/ui";
import { MediaContextProvider, Media } from "utils/MediaUtils";

const Main = (props) => {
  // const { sideBarMenuVisible } = useSelector((state) => state.uiReducer);
  // const dispatch = useDispatch();
  // dispatch(toggleActivemenu(props.activeMenu));
  const { activeMenu } = props;
  console.log("Current menu: ", activeMenu);

  return (
    <div style={{ overflow: "hidden" }}>
      <MediaContextProvider>
        <Media at="mobile">
          <TopBar activeMenu={props.activeMenu} size="mobile" />
          <Sidebar.Pushable style={{ marginTop: -14 }}>
            <Sidebar.Pusher>
              <SideBarMenu activeMenu={props.activeMenu} />
              {props.content || <Dashboard />}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Media>
        <Media at="smartphone">
          <TopBar activeMenu={props.activeMenu} size="smartphone" />
          <Sidebar.Pushable style={{ marginTop: -14 }}>
            <Sidebar.Pusher>
              <SideBarMenu activeMenu={props.activeMenu} />
              {props.content || <Dashboard />}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Media>
        <Media at="tablet">
          <TopBar activeMenu={props.activeMenu} size="tablet" />
          {props.content || <Dashboard />}
        </Media>
        <Media greaterThanOrEqual="computer">
          <TopBar activeMenu={props.activeMenu} size="computer" />
          {props.content || <Dashboard />}
        </Media>
      </MediaContextProvider>
    </div>
  );
};

export default Main;
