import DropdownUser from "components/dropdowns/DropdownUser";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Label,
  List,
  Modal,
  Image,
  Container,
} from "semantic-ui-react";
import { deleteCartObservasi, post } from "store/actions/data";

const CartContent = (props) => {
  const [open, setOpen] = React.useState(false);

  // console.log(props.cartObservasi);
  const dispatch = useDispatch();

  const handleChange = () => {};
  const handleDelete = (id_benda) => {
    console.log(`Deleting ${id_benda}...`);
    dispatch(deleteCartObservasi(id_benda));
  };

  const onChange = (e) => {
    console.log(e);
  };

  return (
    <Modal
      // basic
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={props.children}
    >
      <Header icon>
        <Icon name="tasks" />
        Daftar Pilihan
      </Header>
      <Modal.Content>
        <p>
          Daftar pilihan ini akan disimpan sebagai daftar benda yang akan
          diobservasi pada kegiatan observasi <b>{props.deskripsiObservasi}</b>:
          {props.cartObservasi.length > 0 ? (
            <List divided relaxed verticalAlign="middle">
              {props.cartObservasi.map((val, key) => {
                return (
                  <List.Item>
                    <List.Content floated="right">
                      <a onClick={() => handleDelete(val.id_benda)}>
                        <Icon name="delete" />
                      </a>
                    </List.Content>
                    <List.Content>
                      {val.no_registrasi} / {val.no_inventaris} -{" "}
                      {val.nama_benda}
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
          ) : (
            <Container textAlign="center" style={{ color: "#bbbbb" }}>
              <Image
                centered
                src="/images/list_is_empty.jpg"
                style={{ height: "60%", width: "60%" }}
              />
              <b>List Kosong</b>
              <br />
              Pilih koleksi yang akan diobservasi pada tab "rincian"
              <Divider hidden />
            </Container>
          )}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" onClick={() => setOpen(false)}>
          <Icon name="remove" /> Batal
        </Button>
        {props.cartObservasi.length > 0 ? (
          <Button
            color="green"
            inverted
            onClick={() => {
              setOpen(false);
              props.handleSave();
            }}
          >
            <Icon name="save" /> Simpan
          </Button>
        ) : (
          " "
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default CartContent;
