import * as auth from "../actions/auth";

const INITIAL_STATE = {
  token: "",
  email: "",
  id: "",
  realm: "",
  username: "",
  picture: "",
  isLoading: false,
  // message: "",
};

const authReducer = (state = INITIAL_STATE, action) => {
  // console.log(action);
  switch (action.type) {
    case auth.LOGIN:
      return {
        ...state,
        token: action.token,
        email: action.email,
        id: action.id,
        realm: action.realm,
        username: action.username,
        picture: action.picture,
      };
    case auth.REFRESH_USER_DATA:
      return {
        ...state,
        email: action.email,
        id: action.id,
        realm: action.realm,
        username: action.username,
        picture: action.picture,
      };
    case auth.LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    case auth.TOGGLE_ISLOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    // case auth.SHOW_MESSAGE:
    //   return {
    //     ...state,
    //     message: action.message,
    //   };
    // case auth.CLEAR_MESSAGE:
    //   return {
    //     ...state,
    //     message: "",
    //   };
    default:
      return state;
  }
};

export default authReducer;
