import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Image, Modal, Button, Form, Grid, GridColumn, GridRow, Icon } from "semantic-ui-react";

import { toggleIsLoading } from "store/actions/auth";
import { axiosWithToken } from "utils/AxiosWithToken";
import { API_URL } from "env";

const apiUrl = API_URL;

// const apiServer = `https://papi.musnas.nufaza.com`;
// const apiServer = `http://localhost:3333`;

const ProfileForm = (props) => {
  const { showProfile, setShowProfile, userProfile, handleRefresh } = props;

  const profileInitialState = {
    id: "",
    username: "",
    email: "",
    password: "",
    realm: "",
    picture: "",
  };
  const [formValues, setFormValues] = useState(profileInitialState);

  const [formValidationMessage, setFormValidationMessage] = useState({
    id: "",
    username: "",
    email: "",
    password: "",
    realm: "",
    picture: "",
  });

  const [formValidity, setFormValidity] = useState({
    id: true,
    username: true,
    email: true,
    password: true,
    realm: true,
    picture: null,
  });

  // User Profile Changed
  useEffect(() => {
    if (!userProfile) {
      setFormValues(profileInitialState);
    } else {
      setFormValues({ ...userProfile, password: "passwordpassword" });
    }

    var avatarImg =
      userProfile.picture !== null && userProfile.picture !== "" ? `${apiUrl}/uploads/${userProfile.picture}` : "/icons/avatar_human.png";

    console.log(avatarImg);
    setImageSrc(avatarImg);
  }, [userProfile]);

  // Handle change for non ordinary text input
  const handleChange = (name, value) => {
    console.log(name, value);
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setTimeout(() => {
      console.log(formValues);
    }, 2000);
  };

  // Handle validate for non ordinary text input
  const handleValidate = (name) => {
    var isValid = true;
    var message = "";
    switch (name) {
      default:
        isValid = formValues[name] !== "";
        message = name + " tidak boleh kosong";
        break;
    }
    setFormValidity({
      ...formValidity,
      [name]: isValid,
    });
    if (!isValid) {
      setFormValidationMessage({
        ...formValidationMessage,
        [name]: message,
      });
    }
  };

  // Handle change for ordinary text input
  const change = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  // Handle validate for ordinary text input
  const validate = (e) => {
    var isValid = true;
    var message = "";
    switch (e.target.name) {
      default:
        isValid = e.target.value !== "";
        message = e.target.name + " tidak boleh kosong";
        break;
    }
    setFormValidity({
      ...formValidity,
      [e.target.name]: isValid,
    });
    if (!isValid) {
      setFormValidationMessage({
        ...formValidationMessage,
        [e.target.name]: message,
      });
    }
  };

  const handleChooseImage = (name, image) => {
    setFormValues({
      ...formValues,
      [name]: image,
    });
  };

  const fileEvent = (e) => {
    // console.log(e);
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    handleChooseImage("picture", e.target.files[0]);
  };

  const dispatch = useDispatch();

  const handleSave = () => {
    console.log(formValues);
    var formData = new FormData();

    formData.append("id", formValues.id);
    formData.append("username", formValues.username);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("realm", formValues.realm);
    formData.append("picture", formValues.picture);

    // let url = `/updatePengguna`;
    let url = `/updateOperator`;

    axiosWithToken
      .post(url, formData, {})
      .then((res) => {
        dispatch(toggleIsLoading(false));
        setShowProfile(false);
        props.onFinish(true, "Data saved");
        console.log(res);
      })
      .catch((error) => {
        if (typeof error.response !== "undefined") {
          props.onFinish(false, error.response.data.replace(/(<([^>]+)>)/gi, ""));
          console.log(error);
        }
      });

    dispatch(toggleIsLoading(true));
  };

  const formSize = props.size === "computer" ? "large" : "small";
  const { isLoading } = useSelector((state) => state.authReducer);
  const [imageSrc, setImageSrc] = useState("/icons/avatar_human.png");

  // If props contains user
  // const user = props.user;
  // if (user) {
  //   setFormValues(user);
  // }

  return (
    <Modal onClose={() => setShowProfile(false)} onOpen={() => setShowProfile(true)} open={showProfile} size={formSize} style={{ maxWidth: 550 }}>
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content image>
        <Grid style={{ maxWidth: 280 }}>
          <GridRow>
            <GridColumn textAlign="center" style={{ paddingLeft: 50, paddingRight: 50, paddingBottom: 0 }}>
              <Image size="small" src={imageSrc} wrapped circular />
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn textAlign="center">
              {/* <Button>Pilih Gambar</Button> */}
              <label for="embedpollfileinput" class="ui small floated button">
                <Icon name="upload" />
                Pilih Foto
              </label>
              <input
                type="file"
                onChange={fileEvent}
                id="embedpollfileinput"
                style={{
                  width: 0.1,
                  height: 0.1,
                  opacity: 0,
                  overflow: "hidden",
                  position: "absolute",
                  zIndex: -1,
                }}
              />
            </GridColumn>
          </GridRow>
        </Grid>
        <Modal.Description>
          <Form>
            <Form.Input fluid name="username" value={formValues.username} onChange={change} icon="user" iconPosition="left" placeholder="Nama" />
            <Form.Input fluid name="email" value={formValues.email} onChange={change} icon="mail" iconPosition="left" placeholder="Alamat E-mail" />
            <Form.Input
              fluid
              name="password"
              value={formValues.password}
              onChange={change}
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
            />
            <Form.Select
              name="realm"
              value={formValues.realm}
              onChange={(e, f) => {
                handleChange("realm", f.value);
              }}
              options={[
                { key: "admins", text: "Admin", value: "admins" },
                { key: "users", text: "User", value: "users", selected: true },
              ]}
              placeholder="Peran"
            />
            {/* <ImageUploader
              withPreview={true}
              withIcon={false}
              buttonText="Pilih foto"
              withLabel={false}
              onChange={(pic) => {
                handleChooseImage("picture", pic[0]);
              }}
              imgExtension={[".jpg"]}
              maxFileSize={5242880}
              singleImage={true}
              name="picture"
            /> */}
            {/* <Button
              color="brown"
              fluid
              size="large"
              onClick={handleSave}
              loading={isLoading}
            >
              Simpan
            </Button> */}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="grey" onClick={() => setShowProfile(false)}>
          Batal
        </Button>
        <Button color="brown" content="Simpan" labelPosition="right" icon="checkmark" onClick={handleSave} positive />
      </Modal.Actions>
    </Modal>
  );
};

export default ProfileForm;
