import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MediaContextProvider, Media } from "utils/MediaUtils";
import { useWindowHeight } from "@react-hook/window-size";
import {
  Breadcrumb,
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
  Tab,
  Table,
  Sidebar,
  Message,
  Menu,
  Image,
  ImageGroup,
  Divider,
  Label,
  Modal,
} from "semantic-ui-react";

import FormLaporan from "components/pages/laporan/FormLaporan";
import SidebarForm from "components/elements/SidebarForm";
import { toggleActivetabLaporan } from "store/actions/ui";
import { useAxiosWithToken } from "utils/AxiosWithToken";
import moment from "moment";
import { toTitleCase } from "utils/Lib";
import Subheader from "components/elements/Subheader";

const Head = (props) => {
  const sections = [
    { key: "beranda", content: "Beranda", link: true },
    { key: "rencana_observasi", content: "Laporan Observasi", link: true },
    { key: "benda", content: "Mata Uang", link: true },
  ];
  const { value } = props;

  return (
    <div>
      <Breadcrumb
        icon="right angle"
        sections={sections}
        style={{ marginLeft: 20 }}
      />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        {toTitleCase(value.nama_benda)}
      </Header>
    </div>
  );
};

const Content = (props) => {
  const { id } = useParams();
  const { activeTabLaporan } = useSelector((state) => state.uiReducer);

  const dispatch = useDispatch();
  const handleTabChange = (e, { activeIndex }) =>
    dispatch(toggleActivetabLaporan(activeIndex));

  // const height = useWindowHeight();
  const { size } = props;
  const screenHeight = useWindowHeight();
  const height = size === "computer" ? screenHeight - 100 : screenHeight - 330;

  const { value } = props;

  // Navigation, back
  const history = useHistory();
  return (
    <Container
      fluid
      style={{
        padding: 10,
        width: "100%",
        maxHeight: height,
        minHeight: height,
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Grid columns={16}>
        <Grid.Column width={14} style={{ paddingBottom: 0 }}>
          <Subheader label="DATA BENDA" />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <Icon
            name="close"
            color="grey"
            onClick={() => {
              history.goBack();
            }}
            style={{
              float: "right",
            }}
          />
        </Grid.Column>
      </Grid>

      <Segment>
        <div
          className="ui"
          style={{ color: "#ccc", marginTop: 0, marginLeft: 20 }}
        >
          {value.no_registrasi ? (
            <div>
              No.Registrasi: <b>{value.no_registrasi}</b>;
            </div>
          ) : (
            <div />
          )}
          {value.no_inventaris ? (
            <div>
              No.Inventaris: <b>{value.no_inventaris}</b>;
            </div>
          ) : (
            <div />
          )}
          {value.dimensi_panjang ? (
            <div>
              Panjang: <b>{value.dimensi_panjang} cm</b>;
            </div>
          ) : (
            <div />
          )}
          {value.dimensi_lebar ? (
            <div>
              Lebar: <b>{value.dimensi_lebar} cm</b>;
            </div>
          ) : (
            <div />
          )}
          {value.dimensi_tinggi ? (
            <div>
              Tinggi: <b>{value.dimensi_tinggi} cm</b>;
            </div>
          ) : (
            <div />
          )}
          {value.dimensi_tebal ? (
            <div>
              Tebal: <b>{value.dimensi_tebal} cm</b>;
            </div>
          ) : (
            <div />
          )}
          {value.dimensi_diameter ? (
            <div>
              Diameter: <b>{value.dimensi_diameter} cm</b>;
            </div>
          ) : (
            <div />
          )}
          {value.berat > 0 ? (
            <div>
              Berat: <b>{value.berat} gram</b>;
            </div>
          ) : (
            <div />
          )}
          {value.klasifikasi ? (
            <div>
              Klasifikasi: <b>{value.klasifikasi}</b>;
            </div>
          ) : (
            <div />
          )}
          {value.bahan_koleksi_utama ? (
            <div>
              Bahan Koleksi: <b>{value.bahan_koleksi_utama}</b>;
            </div>
          ) : (
            <div />
          )}
          {value.bahan_koleksi_lainnya ? (
            <div>
              Bahan Koleksi: <b>{value.bahan_koleksi_lainnya}</b>;
            </div>
          ) : (
            <div />
          )}
        </div>
      </Segment>
    </Container>
  );
};

const styles = {
  selected: {
    borderBottomColor: "green",
    borderBottomWidth: "3",
    borderBottomStyle: "solid",
  },
  unselected: {},
};

const DetailInventarisasi = () => {
  const { id } = useParams();
  // return <div>Showing {id}</div>;

  const { sideBarFormVisible } = useSelector((state) => state.uiReducer);

  // const height = useWindowHeight() - 168;
  const height = useWindowHeight();
  console.log(height);

  const addr = `/api/inventarisasi-detail/${id}`;
  console.log(addr);

  const [{ data, loading, error }, refetch] = useAxiosWithToken(addr);

  if (data) {
    console.log(data);
  }

  const value = data ? data[0] : null;
  if (!data) return "no data found";

  return (
    <div>
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <Sidebar.Pushable>
        <MediaContextProvider>
          <Media between={["mobile", "tablet"]}>
            <Sidebar.Pushable>
              <SidebarForm size="mobile">
                <FormLaporan />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <div
                  style={{
                    backgroundColor: "#eee",
                    minHeight: height - 70,
                    maxHeight: height - 80,
                  }}
                >
                  <Grid columns={1} style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column
                      style={{
                        height: "20%",
                        backgroundImage: `url(${"/images/brown_bg_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Head value={value} />
                    </Grid.Column>
                  </Grid>
                  <Content value={value} size="mobile" />
                </div>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
          <Media at="tablet">
            <Sidebar.Pushable>
              <SidebarForm size="computer">
                <FormLaporan />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <div
                  style={{
                    backgroundColor: "#eee",
                    minHeight: height - 70,
                    maxHeight: height - 80,
                  }}
                >
                  <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
                    <Grid.Column
                      style={{
                        height: "20%",
                        backgroundImage: `url(${"/images/brown_bg_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Head value={value} />
                    </Grid.Column>
                  </Grid>
                  <Content value={value} size="tablet" />
                </div>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
          <Media greaterThanOrEqual="computer">
            <Sidebar.Pushable>
              <SidebarForm size="computer">
                <FormLaporan />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <Grid columns={2}>
                  <Grid.Row stretched>
                    <Grid.Column
                      stretched
                      width={6}
                      verticalAlign="middle"
                      style={{
                        height: "100%",
                        backgroundImage: `url(${"/images/brown_bg_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Grid stretched>
                        <Grid.Column stretched verticalAlign="middle">
                          <Head value={value} />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      style={{
                        backgroundColor: "#eee",
                        minHeight: height - 87,
                        maxHeight: height - 87,
                      }}
                    >
                      <Content value={value} size="computer" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
        </MediaContextProvider>
      </Sidebar.Pushable>
    </div>
  );
};

export default DetailInventarisasi;
