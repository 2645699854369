import React, { useState, useEffect } from "react";
// import { useQuery, gql } from "@apollo/client";
import { toggleSidebarForm } from "store/actions/ui";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Icon,
  Segment,
  Tab,
  Table,
  Message,
  Menu,
  Popup,
} from "semantic-ui-react";
import PlaceholderPara from "components/elements/PlaceholderPara";
import { useWindowHeight } from "@react-hook/window-size";
import { EAPI_URL, resetEditForm } from "store/actions/data";
import { useAxiosWithToken } from "utils/AxiosWithToken";
import { options } from "components/dropdowns/DropdownAsalPermintaan";
import EditDeletePopup from "components/elements/EditDeletePopup";
import { del, editForm } from "store/actions/data";
import { renderDate } from "utils/Lib";

const RencanaObservasi = (props) => {
  // Detect update from state
  const { model, success } = useSelector((state) => state.dataReducer);
  const { sideBarFormVisible } = useSelector((state) => state.uiReducer);
  const auth = useSelector((state) => state.authReducer);
  const { callToast } = props;
  const dispatch = useDispatch();
  const handleBuatRencana = () => {
    dispatch(toggleSidebarForm(true));
    dispatch(resetEditForm());
  };
  // Paging shit
  const recPerPage = 8;
  const [offset, setOffset] = useState(0);
  const handleNext = () => {
    setOffset(offset + recPerPage);
  };
  const handlePrev = () => {
    setOffset(offset > 0 ? offset - recPerPage : 0);
  };
  const handleRefresh = () => {
    console.log("Refetching...");
    refetch();
  };

  // Core database management
  // let LIST_OBSERVASIS = gql`
  //   query ListObservasis {
  //     observasis(
  //       filter: {
  //         limit: ${recPerPage}
  //         offset: ${offset}
  //         order: "noObservasi ASC"
  //         # where: { noObservasi: { eq: "2" } }
  //       }
  //     ) {
  //       idObservasi
  //       deskripsiObservasi
  //       estimasiBiaya
  //       idAsalPermintaan
  //       noObservasi
  //       tanggalMulai
  //       tanggalSelesai
  //       tempat
  //     }
  //   }
  // `;

  // const { loading, error, data, refetch } = useQuery(LIST_OBSERVASIS);
  const [{ data, loading, error }, refetch] = useAxiosWithToken(
    `/api/rencana-observasi?limit=${recPerPage}&offset=${offset}`
  );

  /// UI shit, fixing the viewport
  const { size } = props;
  const screenHeight = useWindowHeight();
  const height = size === "computer" ? screenHeight - 228 : screenHeight - 300;

  // Attempt to do things after form save
  useEffect(() => {
    if (model === "observasi" && success) {
      dispatch(toggleSidebarForm(false));
    }
  }, [model, success]);

  useEffect(() => {
    // console.log(`Sidebar state is changed: ${sideBarFormVisible}`);
    if (!sideBarFormVisible) handleRefresh();
  }, [sideBarFormVisible]);

  const checkAsalPermintaan = (id) => {
    const selected = options.find((obj) => {
      return obj.key === id;
    });
    return selected.text;
  };

  var output = [];
  useEffect(() => {
    if (typeof data !== "undefined") {
      output = data;
      // data = rawdata;
      // output = data.map((val, idx) => {
      //   var id_pics = val.id_pics.split(",");
      //   return { ...val, id_pics };
      // });
      console.log("OUTPUT LENGTH: ", output.length);
      console.log("OUTPUT: ", output);
      console.log("DATA: ", data);
    } else {
      //
    }
  }, [data]);

  const deleteHandler = (id) => {
    dispatch(del("observasi", { id_observasi: id }, auth, onFinish));
  };

  const editHandler = (id, data) => {
    dispatch(toggleSidebarForm(true));
    dispatch(editForm("observasi", { id_observasi: id }, data));
  };

  const onFinish = (status, message) => {
    if (status) {
      callToast("Success", "success", "Perubahan berhasil disimpan");
    } else {
      callToast("Error", "error", message);
    }
    handleRefresh();
  };

  const handleRowClick = () => {};

  return (
    <Tab.Pane>
      <Menu borderless secondary style={{ paddingLeft: 5 }}>
        <Button
          icon
          labelPosition="left"
          color="brown"
          onClick={handleBuatRencana}
        >
          <Icon name="add" />
          Buat Rencana
        </Button>
        {/* <Popup
          content="Pilih item untuk "
          trigger={<Button icon="tasks" secondary />}
        /> */}
        <Menu.Menu position="right">
          <Menu.Item onClick={handlePrev}>
            <Icon name="angle left" />
          </Menu.Item>
          {/* <Menu.Item onClick={handleRefresh}>
            <Icon name="refresh" />
          </Menu.Item> */}
          <Menu.Item>{offset / recPerPage + 1}</Menu.Item>
          <Menu.Item onClick={handleNext}>
            <Icon name="angle right" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {/* <Form.Field inline>
      {/* <Input icon="search" placeholder="Cari.." /> */}
      {/* </Form.Field> */}
      <Segment style={{ padding: 0, overflow: "auto", maxHeight: height }}>
        {error ? <Message>There is an error: {error}</Message> : ""}
        <Table striped style={{ borderWidth: 0 }} unstackable selectable>
          <Table.Header>
            {size !== "mobile" ? (
              <Table.Row>
                <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                <Table.HeaderCell width={4}>Judul</Table.HeaderCell>
                <Table.HeaderCell width={2}>Jadwal</Table.HeaderCell>
                {/* <Table.HeaderCell width={2}>Tgl Mulai</Table.HeaderCell>
                <Table.HeaderCell width={2}>Tgl Selesai</Table.HeaderCell> */}
                <Table.HeaderCell width={3}>Tempat</Table.HeaderCell>
                {/* <Table.HeaderCell width={2}>Estimasi Biaya</Table.HeaderCell> */}
                {/* <Table.HeaderCell width={3}>Asal Permintaan</Table.HeaderCell> */}
                <Table.HeaderCell width={1}></Table.HeaderCell>
              </Table.Row>
            ) : (
              <Table.Row>
                <Table.HeaderCell width={5}>Judul</Table.HeaderCell>
                <Table.HeaderCell width={1}></Table.HeaderCell>

                {/* <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                <Table.HeaderCell width={4}>Deskripsi</Table.HeaderCell>
                <Table.HeaderCell width={2}>Jadwal</Table.HeaderCell>
                <Table.HeaderCell width={1}></Table.HeaderCell> */}
                {/* <Table.HeaderCell width={2}>Tgl Mulai</Table.HeaderCell>
                <Table.HeaderCell width={2}>Tgl Selesai</Table.HeaderCell> */}
              </Table.Row>
            )}
          </Table.Header>
          {!loading && typeof data !== "undefined" ? (
            <Table.Body>
              {/* {data.bendas.map(({ value, key }) => { */}
              {data.map((value, key) =>
                size !== "mobile" ? (
                  <Table.Row
                    key={key}
                    onClick={() => {
                      handleRowClick(value.id_observasi);
                    }}
                  >
                    <Table.Cell>{value.no_observasi}</Table.Cell>
                    <Table.Cell>{value.judul_observasi}</Table.Cell>
                    <Table.Cell>
                      {renderDate(value.tanggal_mulai)}-{" "}
                      {renderDate(value.tanggal_selesai)}
                    </Table.Cell>
                    {/* <Table.Cell>{renderDate(value.tanggal_mulai)}</Table.Cell>
                    <Table.Cell>{renderDate(value.tanggal_selesai)}</Table.Cell> */}
                    <Table.Cell>{value.nama_lokasi}</Table.Cell>
                    {/* <Table.Cell>{value.estimasi_biaya}</Table.Cell>
                    <Table.Cell>
                      {checkAsalPermintaan(value.id_asal_permintaan)}
                    </Table.Cell> */}
                    <Table.Cell>
                      <EditDeletePopup
                        trigger={<Icon name="ellipsis vertical" />}
                        delete={deleteHandler}
                        edit={editHandler}
                        id={value.id_observasi}
                        data={value}
                      />
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <Table.Row>
                    {/* <Table.Cell>{value.no_observasi}</Table.Cell>
                    <Table.Cell>{value.judul_observasi}</Table.Cell>
                    <Table.Cell>
                      {renderDate(value.tanggal_mulai)}- {renderDate(value.tanggal_selesai)}
                    </Table.Cell>
                    {/* <Table.Cell>{renderDate(value.tanggal_mulai)}</Table.Cell>
                    <Table.Cell>{renderDate(value.tanggal_selesai)}</Table.Cell> */}
                    <Table.Cell>
                      Nomor: <b>{value.no_observasi}</b>
                      <br />
                      Jadwal: {renderDate(value.tanggal_mulai)} -{" "}
                      {renderDate(value.tanggal_selesai)}
                      <br />
                      <i>{value.judul_observasi}</i>
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      <EditDeletePopup
                        trigger={<Icon name="ellipsis vertical" />}
                        delete={deleteHandler}
                        edit={editHandler}
                        id={value.id_observasi}
                        data={value}
                        style={{
                          float: "right",
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          ) : size !== "mobile" ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Segment>
    </Tab.Pane>
  );
};

export default RencanaObservasi;
