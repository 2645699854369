import React, { Component, useState } from "react";
import { Grid, Popup, Button, Icon, Menu, Divider } from "semantic-ui-react";
const timeOutLength = 2500;

const EditDeletePopup = (props) => {
  const [isOpen, setisOpen] = useState(false);
  var timeout = {};
  const handleOpen = () => {
    setisOpen(true);
    timeout = setTimeout(() => {
      setisOpen(false);
    }, timeOutLength);
  };

  const handleClose = () => {
    setisOpen(false);
    clearTimeout(timeout);
  };

  return (
    <Popup
      wide
      on="click"
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      {...props}
    >
      <Icon
        name="pencil"
        color="blue"
        onClick={() => {
          setisOpen(false);
          props.edit(props.id, props.data);
        }}
        style={{ marginLeft: 0, marginRight: 10 }}
      />
      <Divider vertical />
      <Icon
        name="close"
        color="red"
        onClick={() => {
          setisOpen(false);
          console.log("Delete clicked");
          props.delete(props.id);
        }}
        style={{ marginLeft: 10, marginRight: 0 }}
      />

      {/* <Grid columns="equal">
        <Grid.Column>
          <Popup
            trigger={<Button color="blue" icon="pencil" fluid />}
            content="Ubah."
            position="top center"
            size="tiny"
            inverted
          />
        </Grid.Column>
        <Grid.Column>
          <Popup
            trigger={<Button color="red" icon="close" fluid />}
            content="Hapus."
            position="top center"
            size="tiny"
            inverted
          />
        </Grid.Column>
      </Grid> */}
    </Popup>
  );
};

export default EditDeletePopup;
