import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MediaContextProvider, Media } from "utils/MediaUtils";
import { useWindowHeight } from "@react-hook/window-size";

import RencanaObservasi from "./observasi/RencanaObservasi";
import FormObservasi from "components/pages/observasi/FormObservasi";
import MappingKoleksi from "./observasi/MappingKoleksi";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";

// import PenugasanObservasi from "./observasi/PenugasanObservasi";

import {
  Breadcrumb,
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
  Tab,
  Table,
  Sidebar,
  Message,
  Menu,
} from "semantic-ui-react";
import SidebarForm from "components/elements/SidebarForm";
import Cart from "components/pages/observasi/Cart";
import CartContent from "components/pages/observasi/CartContent";
import { toggleActivetabObservasi } from "store/actions/ui";

const Head = (props) => {
  const sections = [
    { key: "beranda", content: "Beranda", link: true },
    { key: "rencana_observasi", content: "Rencana Observasi", link: true },
  ];
  return (
    <div>
      <Breadcrumb
        icon="right angle"
        sections={sections}
        style={{ marginLeft: 20 }}
      />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        Rencana Observasi
      </Header>
    </div>
  );
};

const Content = (props) => {
  const { activeTabObservasi } = useSelector((state) => state.uiReducer);

  const dispatch = useDispatch();
  const handleTabChange = (e, { activeIndex }) =>
    dispatch(toggleActivetabObservasi(activeIndex));

  const height = useWindowHeight();
  const panes = [
    {
      menuItem: "Rencana",
      render: () => <RencanaObservasi callToast={callToast} {...props} />,
    },
    { menuItem: "Rincian", render: () => <MappingKoleksi /> },
    // { menuItem: "Laporan", render: () => <PenugasanObservasi /> },
  ];
  return (
    <Container style={{ padding: 10, width: "100%" }}>
      {/* <Subheader label="SEKILAS DATA" /> */}
      <Tab
        panes={panes}
        activeIndex={activeTabObservasi}
        onTabChange={handleTabChange}
      />
    </Container>
  );
};

const callToast = (title, type, message) => {
  toast(
    {
      title: title,
      type: type,
      description: `${message}`,
    },
    () => console.log("toast closed"),
    () => console.log("toast clicked"),
    () => console.log("toast dismissed")
  );
};

const Observasi = () => {
  const { sideBarFormVisible } = useSelector((state) => state.uiReducer);
  const { bottomCartVisible } = useSelector((state) => state.uiReducer);

  // const height = useWindowHeight() - 168;
  const height = useWindowHeight();
  // console.log(height);
  return (
    <div>
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <Sidebar.Pushable>
        <MediaContextProvider>
          <Media between={["mobile", "tablet"]}>
            <Sidebar.Pushable>
              <SidebarForm size="mobile">
                <FormObservasi callToast={callToast} toast={toast} />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <div
                  style={{
                    backgroundColor: "#eee",
                    minHeight: height - 70,
                    maxHeight: height - 80,
                  }}
                >
                  <Grid columns={1} style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column
                      style={{
                        height: "20%",
                        backgroundImage: `url(${"images/konservasi_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Head />
                    </Grid.Column>
                  </Grid>
                  <Content size="mobile" />
                </div>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
          <Media at="tablet">
            <Sidebar.Pushable>
              <SidebarForm size="computer">
                <FormObservasi callToast={callToast} toast={toast} />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <div
                  style={{
                    backgroundColor: "#eee",
                    minHeight: height - 70,
                    maxHeight: height - 80,
                  }}
                >
                  <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
                    <Grid.Column
                      style={{
                        height: "20%",
                        backgroundImage: `url(${"images/konservasi_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Head />
                    </Grid.Column>
                  </Grid>
                  <Content size="tablet" />
                </div>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
          <Media greaterThanOrEqual="computer">
            <Sidebar.Pushable>
              <SidebarForm size="computer">
                <FormObservasi callToast={callToast} toast={toast} />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <Grid columns={2}>
                  <Grid.Row stretched>
                    <Grid.Column
                      stretched
                      width={6}
                      verticalAlign="middle"
                      style={{
                        height: "100%",
                        backgroundImage: `url(${"images/konservasi_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Grid stretched>
                        <Grid.Column stretched verticalAlign="middle">
                          <Head />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      style={{
                        backgroundColor: "#eee",
                        minHeight: height - 87,
                        maxHeight: height - 87,
                      }}
                    >
                      <Content size="computer" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
        </MediaContextProvider>
        {bottomCartVisible ? <Cart /> : <div />}
      </Sidebar.Pushable>
      <SemanticToastContainer />
    </div>
  );
};

export default Observasi;
