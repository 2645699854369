import { combineReducers } from "redux";
import uiReducer from "./ui";
import authReducer from "./auth";
import dataReducer from "./data";

export default combineReducers({
  uiReducer,
  authReducer,
  dataReducer,
});
