import { axiosWithToken } from "utils/AxiosWithToken";
import { clean } from "utils/Lib";

export const API_POST = "API_POST";
export const API_PUT = "API_PUT";
export const API_DELETE = "API_DELETE";

export const TOGGLE_ISLOADING = "TOGGLE_ISLOADING";
export const TOGGLE_IDOBSERVASI = "TOGGLE_IDOBSERVASI";
export const TOGGLE_IDOBSERVASIRINCI = "TOGGLE_IDOBSERVASIRINCI";
export const INSERT_CARTOBSERVASI = "INSERT_CARTOBSERVASI";
export const DELETE_CARTOBSERVASI = "DELETE_CARTOBSERVASI";

export const EDIT_FORM = "EDIT_FORM";
export const RESET_EDIT_FORM = "RESET_EDIT_FORM";

export const put = (model, data, params, auth, onFinish) => async (
  dispatch
) => {
  console.log("model: ", model);
  console.log("data: ", data);
  console.log("params: ", params);

  let token = auth.token;
  try {
    // Execute
    var url = `/model/${model}`;
    console.log(url);
    let response = await axiosWithToken.put(url, data, {
      params: params,
      headers: { Authorization: `Bearer ${token}` },
    });
    // Stop any loading
    // dispatch(toggleIsLoading(false));
    // Finish, and tell them the response
    // console.log(response);
    dispatch({
      type: API_PUT,
      method: "put",
      model: model,
      success: response.status === 200,
      status: response.status,
      message: response.statusText,
    });
    onFinish(true, response);
  } catch (error) {
    // Stop any loading
    // dispatch(toggleIsLoading(false));
    // Finish, but tell them there's an error
    // console.log(error);
    onFinish(false, error.response.data.replace(/(<([^>]+)>)/gi, ""));
  }
};

export const post = (model, data, auth, onFinish) => async (dispatch) => {
  let token = auth.token;
  try {
    // Execute
    var url = `/model/${model}`;
    console.log(url);

    let response = await axiosWithToken.post(url, clean(data), {
      params: {},
      headers: { Authorization: `Bearer ${token}` },
    });
    // Stop any loading
    // dispatch(toggleIsLoading(false));
    // Finish, and tell them the response
    // console.log(response);
    dispatch({
      type: API_POST,
      method: "post",
      model: model,
      success: response.status === 200,
      status: response.status,
      message: response.statusText,
    });
    onFinish(true, response, data);
  } catch (error) {
    // Stop any loading
    // dispatch(toggleIsLoading(false));
    // Finish, but tell them there's an error
    // console.log(error);
    onFinish(false, error.response.data.replace(/(<([^>]+)>)/gi, ""), data);
  }
};

export const del = (model, params, auth, onFinish) => async (dispatch) => {
  let token = auth.token;
  console.log("running delete..");
  try {
    // Execute
    var url = `/model/${model}`;
    let response = await axiosWithToken.delete(url, {
      params: params,
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: API_DELETE,
      method: "delete",
      model: model,
      success: response.status === 200,
      status: response.status,
      message: response.statusText,
    });
    onFinish(true, response);
  } catch (error) {
    // Stop any loading
    // dispatch(toggleIsLoading(false));
    // Finish, but tell them there's an error
    // console.log(error);
    onFinish(false, error.response.data.replace(/(<([^>]+)>)/gi));
  }
};

// export const post = (model, data, auth, onFinish) => async (dispatch) => {
//   // console.log(model, data, auth);
//   // return;

//   // Get Token
//   let token = auth.token;
//   try {
//     // Execute
//     let response = await axiosWithToken.post(API_URL + "/" + model, data, {
//       params: {},
//       headers: { Authorization: `Bearer ${token}` },
//     });

//     // Callback
//     dispatch(toggleIsLoading(false));
//     dispatch({
//       type: API_POST,
//       method: "post",
//       model: model,
//       success: response.status === 200,
//       status: response.status,
//       message: response.statusText,
//     });

//     console.log(model);
//     if (model === "observasi-rincis") {
//       console.log("Deleting", data);
//       dispatch(deleteCartObservasi(data.id_benda));
//     }
//     onFinish(response);
//   } catch (e) {
//     dispatch(toggleIsLoading(false));
//     onFinish(e);
//   }
// };

export const toggleIsLoading = (isLoading) => {
  return { type: TOGGLE_ISLOADING, isLoading: isLoading };
};

export const toggleIdObservasi = (idObservasi, deskripsiObservasi) => {
  return {
    type: TOGGLE_IDOBSERVASI,
    idObservasi: idObservasi,
    deskripsiObservasi: deskripsiObservasi,
  };
};

export const toggleIdObservasiRinci = (
  idObservasiRinci,
  obyekObservasiRinci,
  deskripsiObyekObservasi
) => {
  // console.log(
  //   "Action toggle observasirinci kicked: ",
  //   idObservasiRinci,
  //   obyekObservasiRinci,
  //   deskripsiObyekObservasi
  // );
  return {
    type: TOGGLE_IDOBSERVASIRINCI,
    idObservasiRinci: idObservasiRinci,
    obyekObservasiRinci: obyekObservasiRinci,
    deskripsiObyekObservasi: deskripsiObyekObservasi,
  };
};

export const insertCartObservasi = (data) => {
  return { type: INSERT_CARTOBSERVASI, data: data };
};

export const deleteCartObservasi = (id_benda) => {
  return { type: DELETE_CARTOBSERVASI, id_benda: id_benda };
};

// export const saveCartObservasi = (id_benda) => {
//   return { type: SAVE_CARTOBSERVASI, id_benda: id_benda };
// };

export const insertPelaksanaanList = (observasiRinci) => async (dispatch) => {
  console.log(observasiRinci);
};

export const editForm = (editModel, editParam, editData) => {
  return {
    type: EDIT_FORM,
    editModel: editModel,
    editParam: editParam,
    editData: editData,
  };
};

export const resetEditForm = () => {
  return { type: RESET_EDIT_FORM };
};
