import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { useAxiosWithToken } from "utils/AxiosWithToken";

const DropdownPic = (props) => {
  // const [selected, setSelected] = useState(props.value);
  // const [selected, setSelected] = useState([
  //   "e0aa8f58-39ba-44d3-87c8-1b3f6cae99a1",
  //   "2dfb9974-89b7-4ef9-aa82-fc648416faf9",
  // ]);
  const [selectedText, setSelectedText] = useState(props.text);

  const [{ data, loading, error }, refetch] = useAxiosWithToken(
    "/api/users?realm=users"
  );

  const handleChange = (v) => {
    // console.log(v);
    // var newSel = [...selected];
    // if (v.length <= 4) {
    //   setSelected(v);
    // }
  };

  if (loading) return "loading list..";
  if (error) return "error when loading list of operators ..";

  const options = data.map((val, key) => {
    // console.log(val);
    return {
      key: val.id,
      value: val.id,
      text: props.complete ? `${val.username} (${val.email})` : val.username,
    };
  });

  return (
    <Dropdown
      {...props}
      options={options}
      multiple
      selection
      // value={selected}
      // style={{ minWidth: 120 }}
      onChange={(e, f) => {
        // handleChange(f.value);
        if (f.value.length <= 4) {
          props.onChange(f.value);
        }
      }}
    />
  );
};

export default DropdownPic;
