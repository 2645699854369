import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebarMenu } from "store/actions/ui";
import {
  MenuBeranda,
  MenuInventarisasi,
  MenuKondisiKoleksi,
  MenuObservasi,
  MenuLaporan,
  MenuPengguna,
  MenuUnduhan,
  MenuTentangAplikasi,
} from "components/elements/MenuItems";

import { Icon, Image, Menu, Dropdown } from "semantic-ui-react";
import Logo from "./Logo";
import Avatar from "./Avatar";

const LogoImage = ({ size }) => {
  const height = size === "small" ? 45 : 55;
  const marginVert = size === "small" ? 10 : 15;
  const marginLeft = size === "small" ? 25 : 35;
  const marginRight = size === "small" ? 10 : 20;
  return (
    <Image
      src={Logo.color}
      verticalAlign="middle"
      spaced={("left", "right")}
      style={{
        height: height,
        marginTop: marginVert,
        marginBottom: marginVert,
        marginLeft: marginLeft,
        marginRight: marginRight,
      }}
    />
  );
};

// Menu Bar
const SidebarButton = (props) => {
  const { sideBarMenuVisible } = useSelector((state) => state.uiReducer);
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(toggleSidebarMenu());
  };

  return (
    <Menu.Menu position="right">
      <Menu.Item position="right" active={false} onClick={toggle}>
        {sideBarMenuVisible ? <Icon name="close" /> : <Icon name="sidebar" />}
      </Menu.Item>
    </Menu.Menu>
  );
};

// Smaller Screen
const MobileBar = (props) => {
  return (
    <Menu text size="large" style={{ marginTop: 0 }}>
      <LogoImage size="small" />
      <SidebarButton />
    </Menu>
  );
};

// Smaller Screen
const SmartphoneBar = (props) => {
  return (
    <Menu text size="large" style={{ marginTop: 0 }}>
      <LogoImage />
      <SidebarButton />
    </Menu>
  );
};

// 768 screen up
const TabletBar = (props) => {
  const { name, email } = useSelector((state) => state.authReducer);
  return (
    // borderless secondary
    <Menu text size="large" style={{ marginTop: 0, marginBottom: 0 }}>
      <LogoImage />
      <MenuBeranda {...props} />
      <MenuInventarisasi {...props} />
      {/* <MenuKondisiKoleksi {...props} /> */}
      <MenuObservasi {...props} />
      <MenuLaporan {...props} />
      <Dropdown
        item
        className="icon"
        icon="info circle"
        style={{ color: "#2185d0" }}
      >
        <Dropdown.Menu size="large">
          {/* <MenuPengguna {...props} /> */}
          <MenuUnduhan {...props} />
          <MenuTentangAplikasi {...props} />
          {/* <Dropdown.Item>Tentang Aplikasi</Dropdown.Item>
          <Dropdown.Item>Unduhan</Dropdown.Item>
          <Dropdown.Item>Tentang Aplikasi</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Menu position="right">
        <Avatar size="small" />
      </Menu.Menu>
    </Menu>
  );
};

// 1024 screen up
const ComputerBar = (props) => {
  const { name, email } = useSelector((state) => state.authReducer);
  return (
    <Menu text size="large" style={{ marginTop: 0, marginBottom: 0 }}>
      <LogoImage />
      <MenuBeranda {...props} />
      <MenuInventarisasi {...props} />
      {/* <MenuKondisiKoleksi {...props} /> */}
      <MenuObservasi {...props} />
      <MenuLaporan {...props} />
      <Dropdown
        item
        className="icon"
        icon="info circle"
        style={{ color: "#2185d0" }}
      >
        <Dropdown.Menu size="large">
          {/* <MenuPengguna {...props} /> */}
          <MenuUnduhan {...props} />
          <MenuTentangAplikasi {...props} />
          {/* <Dropdown.Item>Tentang Aplikasi</Dropdown.Item>
          <Dropdown.Item>Unduhan</Dropdown.Item>
          <Dropdown.Item>Tentang Aplikasi</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Menu position="right">
        <Avatar />
      </Menu.Menu>
    </Menu>
  );
};

const TopBar = (props) => {
  const { size } = props;
  switch (size) {
    case "mobile":
      return <MobileBar {...props} />;
    case "smartphone":
      return <SmartphoneBar {...props} />;
    case "tablet":
      return <TabletBar {...props} />;
    default:
      return <ComputerBar {...props} />;
  }
};

export default TopBar;
