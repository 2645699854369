import React, { useState, useEffect } from "react";
// import { useQuery, gql } from "@apollo/client";
import { toggleSidebarForm } from "store/actions/ui";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import {
  Button,
  Icon,
  Input,
  Segment,
  Tab,
  Table,
  Menu,
} from "semantic-ui-react";
import PlaceholderPara from "components/elements/PlaceholderPara";
import { useWindowHeight } from "@react-hook/window-size";
// import {
//   BoxDesktop,
//   BoxTablet,
//   BoxMobile,
//   BoxDefault,
// } from "components/elements/Responsive";
import DropdownFilter from "components/dropdowns/DropdownFilter";
// import DropdownAsalPermintaan from "components/dropdowns/DropdownAsalPermintaan";
import useScreenSize from "utils/ScreenSize";
import { toggleBottomCart } from "store/actions/ui";
import { insertCartObservasi } from "store/actions/data";

import { useAxiosWithToken } from "utils/AxiosWithToken";

const MappingKoleksi = (props) => {
  // UI horizontal setting from parent
  // const { size } = props;
  const size = useScreenSize();

  // UI vertical setting
  const screenHeight = useWindowHeight();
  // const topBarHeightComputer = 248;
  // const topBarHeightOther = 320;
  // const topBarHeightComputer = 348;
  // const topBarHeightOther = 420;
  // const topBarHeightComputer = 150;
  const topBarHeightComputer = 150;
  const topBarHeightTablet = 230;
  const topBarHeightMobile = 230;

  var theHeight = 0;
  var segHeight = 0;
  var pageSize = 0;
  var buttonBottom = 0;
  var buttonRight = 0;

  switch (size) {
    case "computer":
      theHeight = screenHeight - topBarHeightComputer;
      segHeight = theHeight - 100;
      pageSize = 20;
      buttonBottom = 40;
      buttonRight = 30;
      break;
    case "tablet":
      theHeight = screenHeight - topBarHeightTablet;
      segHeight = theHeight - 100;
      pageSize = 15;
      buttonBottom = 40;
      buttonRight = 30;
      break;
    case "mobile":
      theHeight = screenHeight - topBarHeightMobile;
      segHeight = theHeight - 85;
      pageSize = 6;
      buttonBottom = 5;
      buttonRight = 0;
      break;
  }
  const height = theHeight;
  const segmentHeight = segHeight;

  // console.log("-------------------");
  // console.log("Screen Size: ", size);
  // console.log("Screen Height: ", screenHeight);
  // console.log("Calculated height", height);

  // Mapping shit
  // const [idObservasi, setIdObservasi] = useState("");
  // const [showDDObservasi, setShowDDObservasi] = useState(false);

  const dispatch = useDispatch();
  const handleBuatDaftar = () => {
    // setShowDDObservasi(true);
    dispatch(toggleBottomCart(true));
  };

  const handleCancel = () => {
    // setShowDDObservasi(false);
  };

  const handleChangeIdObservasi = (val) => {
    // setIdObservasi(val);
    // console.log(val);
  };

  const { bottomCartVisible } = useSelector((state) => state.uiReducer);
  const { cartObservasi, idObservasi } = useSelector(
    (state) => state.dataReducer
  );

  //////////// DATA STUFF //////////////

  // Paging shit
  const recPerPage = pageSize;
  const [offset, setOffset] = useState(0);

  // Paging button handler
  const handleNext = () => {
    setOffset(offset + recPerPage);
  };
  const handlePrev = () => {
    setOffset(offset > 0 ? offset - recPerPage : 0);
  };
  const handleRefresh = () => {
    refetch();
  };
  // Filtering Shit
  const [filter, setFilter] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("nama_benda");
  const handleChangeFilterCriteria = (v) => {
    setFilterCriteria(v);
  };
  const handleChangeFilter = (e) => {
    setFilter(e.target.value);
    setOffset(0);
  };
  // Debouncing Filter
  const [debFilter] = useDebounce(filter, 1500);
  // const filterString =
  //   debFilter !== ""
  //     ? `where: { ${filterCriteria}: { eq: "${debFilter}" } }`
  //     : "";
  const filterString =
    debFilter !== "" ? `&${filterCriteria}=${debFilter}` : "";

  // Core database handler
  // const listKondisiQuery = `
  //   query ListKondisiBendas {
  //     bendas(
  //       filter: {
  //         limit: ${recPerPage}
  //         offset: ${offset}
  //         order: "noRegistrasi ASC",
  //         ${filterString}
  //       }
  //     ) {
  //       idBenda
  //       namaBenda
  //       noInventaris
  //       noRegistrasi
  //       idKondisi
  //     }
  //   }
  // `;
  // const LIST_KONDISI = gql(listKondisiQuery);
  // const { loading, error, data, refetch } = useQuery(LIST_KONDISI);

  const [{ data, loading, error }, refetch] = useAxiosWithToken(
    // `http://localhost:3333/api/laporan-observasi`
    `/api/inventarisasi?limit=${recPerPage}&offset=${offset}${filterString}`
  );

  // Reference
  var kondisi = [];
  kondisi["1"] = "Baik";
  kondisi["2"] = "Rusak Ringan";
  kondisi["3"] = "Rusak Sedang";
  kondisi["4"] = "Rusak Berat";
  kondisi["5"] = "Rusak Total";

  // Cart observasi
  var arrObservasi = [];
  cartObservasi.map((cartItem) => {
    arrObservasi[cartItem.id_benda] = 1;
  });

  // Adding shit
  const handleAdd = (bendaObj) => {
    // console.log(bendaObj);

    const duplicate = cartObservasi.find(
      (x) => x.id_benda === bendaObj.id_benda
    );
    if (!duplicate) {
      dispatch(insertCartObservasi(bendaObj));
    } else {
      console.log(`Benda ini (${duplicate.nama_benda}) sudah diinput`);
    }
  };

  return (
    <Tab.Pane style={{ minHeight: height }}>
      <Menu borderless secondary style={{ paddingLeft: 5 }}>
        <DropdownFilter onChange={handleChangeFilterCriteria} />
        <Input
          icon="search"
          placeholder="Cari.."
          onChange={handleChangeFilter}
          style={{ minWidth: 50 }}
        />
        {size === "mobile" ? (
          <Menu.Menu position="right">
            <Menu.Item onClick={handlePrev} fitted="horizontally">
              <Icon name="angle left" />
            </Menu.Item>
            <Menu.Item>{offset / recPerPage + 1}</Menu.Item>
            <Menu.Item onClick={handleNext} fitted="horizontally">
              <Icon name="angle right" />
            </Menu.Item>
          </Menu.Menu>
        ) : (
          <Menu.Menu position="right">
            <Menu.Item onClick={handlePrev}>
              <Icon name="angle left" />
            </Menu.Item>
            <Menu.Item>{offset / recPerPage + 1}</Menu.Item>
            <Menu.Item onClick={handleNext}>
              <Icon name="angle right" />
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
      <Segment
        style={{
          padding: 0,
          overflow: "auto",
          // minHeight: 200,
          maxHeight: segmentHeight,
          position: "relative",
        }}
      >
        {size === "mobile" ? (
          <Table striped style={{ borderWidth: 0 }} unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>Nomor</Table.HeaderCell>
                <Table.HeaderCell width={5}>Nama</Table.HeaderCell>
                <Table.HeaderCell width={4}>Kondisi</Table.HeaderCell>
                <Table.HeaderCell width={1}> </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {!loading && data ? (
              <Table.Body>
                {/* {data.bendas.map(({ value, key }) => { */}
                {data.map((value, key) => (
                  <Table.Row key={key}>
                    <Table.Cell>
                      <b>{value.no_registrasi}</b>
                      <br />
                      {value.no_inventaris}
                    </Table.Cell>
                    <Table.Cell>{value.nama_benda}</Table.Cell>
                    <Table.Cell>{kondisi[value.id_kondisi]}</Table.Cell>
                    <Table.Cell>
                      <a
                        onClick={() => {
                          // console.log(value);
                          handleAdd(value);
                        }}
                      >
                        {idObservasi !== "" ? (
                          arrObservasi[value.id_benda] ? (
                            <Icon name="check" color="green" />
                          ) : (
                            <Icon name="add circle" color="blue" />
                          )
                        ) : (
                          ""
                        )}
                      </a>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            ) : (
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
          </Table>
        ) : (
          <Table striped style={{ borderWidth: 0 }} unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>No.Reg</Table.HeaderCell>
                <Table.HeaderCell width={2}>No.Inv</Table.HeaderCell>
                <Table.HeaderCell width={6}>Nama</Table.HeaderCell>
                <Table.HeaderCell width={3}>Kondisi</Table.HeaderCell>
                <Table.HeaderCell width={1}> </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {!loading && data ? (
              <Table.Body>
                {/* {data.bendas.map(({ value, key }) => { */}
                {data.map((value, key) => (
                  <Table.Row key={key}>
                    <Table.Cell>{value.no_registrasi}</Table.Cell>
                    <Table.Cell>{value.no_inventaris}</Table.Cell>
                    <Table.Cell>{value.nama_benda}</Table.Cell>
                    <Table.Cell>{kondisi[value.id_kondisi]}</Table.Cell>
                    <Table.Cell>
                      <a
                        onClick={() => {
                          // console.log(value);
                          handleAdd(value);
                        }}
                      >
                        {idObservasi !== "" ? (
                          arrObservasi[value.id_benda] ? (
                            <Icon name="check" color="green" />
                          ) : (
                            <Icon name="add circle" color="blue" />
                          )
                        ) : (
                          ""
                        )}
                      </a>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            ) : (
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
          </Table>
        )}
      </Segment>
      {bottomCartVisible ? (
        <div />
      ) : (
        <Button
          color="green"
          size="big"
          circular
          onClick={handleBuatDaftar}
          style={{
            position: "absolute",
            right: buttonRight,
            bottom: buttonBottom,
            float: "right",
            boxShadow: "0 0 6px rgba(0,0,0,.24), 0 8px 12px rgba(0,0,0,.14)",
          }}
        >
          Tampilkan Daftar
        </Button>
      )}
    </Tab.Pane>
  );
};

export default MappingKoleksi;
