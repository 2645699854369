import React, { useState } from "react";
import {
  Breadcrumb,
  Container,
  Divider,
  Grid,
  Header,
  Progress,
  Button,
  Icon,
  Segment,
  Tab,
  Table,
  Message,
  Menu,
  Image,
} from "semantic-ui-react";
import { MediaContextProvider, Media } from "utils/MediaUtils";
import StatNumber from "components/elements/StatNumber";
import Subheader from "components/elements/Subheader";
import { useWindowHeight } from "@react-hook/window-size";

import { toggleSidebarForm } from "store/actions/ui";
import { useDispatch, useSelector } from "react-redux";
import PlaceholderPara from "components/elements/PlaceholderPara";

import { useAxiosWithToken } from "utils/AxiosWithToken";

const Head = (props) => {
  const sections = [
    { key: "beranda", content: "Beranda", link: true },
    { key: "tentang_aplikasi", content: "Tentang Aplikasi", link: true },
  ];
  return (
    <div>
      <Breadcrumb
        icon="right angle"
        sections={sections}
        style={{ marginLeft: 20 }}
      />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        Tentang Aplikasi
      </Header>
    </div>
  );
};

const Content = () => {
  const height = useWindowHeight();
  return (
    <div style={{ paddingTop: 10 }}>
      <Segment>
        {/* <Form.Field inline>
      {/* <Input icon="search" placeholder="Cari.." /> */}
        {/* </Form.Field> */}
        <Header as="h3">Aplikasi Database Museum - Observasi</Header>
        <Container textAlign="justified">
          Aplikasi ini dikembangkan dari Aplikasi Database Museum yang dibangun
          mulai pada tahun 2016 lalu. Aplikasi ini masih menggunakan basis data
          yang sama dengan basis data Museum Nasional yang telah digunakan
          sebagai acuan penyimpanan referensi koleksi Museum Nasional. Pada
          aplikasi ini, pengguna dapat melakukan tahap-tahap pelaksanaan
          observasi dari mulai perencanaan , pelaksanaan sampai pencetakan
          laporan observasi.
        </Container>
      </Segment>
    </div>
  );
};
const TentangAplikasi = () => {
  const height = useWindowHeight();
  return (
    <div>
      {/* <div style={{ backgroundColor: "#eee", width }}/> */}
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <MediaContextProvider>
        <Media between={["mobile", "tablet"]}>
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height - 70,
              maxHeight: height - 80,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/foto_information_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <div style={{ overflow: "scroll", maxHeight: height - 100 }}>
              <Content />
            </div>
          </div>
        </Media>
        <Media at="tablet">
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height,
              maxHeight: height,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/foto_information_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <Content />
          </div>
        </Media>
        <Media greaterThanOrEqual="computer">
          <Grid columns={2} style={{ height: "90vh" }}>
            <Grid.Row stretched>
              <Grid.Column
                stretched
                width={6}
                // textAlign="center"
                verticalAlign="middle"
                style={{
                  height: "100%",
                  backgroundImage: `url(${"images/foto_information_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  // marginTop: 15,
                }}
              >
                <Grid stretched>
                  <Grid.Column stretched verticalAlign="middle">
                    <Head />
                    {/* <Breadcrumb icon="right angle" sections={sections} style={{ marginLeft: 20 }} />
                <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
                  Aplikasi Database Koleksi
                </Header> */}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column width={10} style={{ backgroundColor: "#eee" }}>
                <Content />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
      {/* <Header as="h3">Dashboard</Header>
  <Image src="/images/paragraph.png" /> */}
    </div>
  );
};

export default TentangAplikasi;
