import React from "react";
import { Route, Switch } from "react-router-dom";
import Main from "components/Main";
import Login from "components/auth/Login";
import Inventarisasi from "components/pages/Inventarisasi";
import DetailInventarisasi from "components/pages/inventarisasi/DetailInventarisasi";
import KondisiKoleksi from "components/pages/KondisiKoleksi";
import Observasi from "components/pages/Observasi";
import Laporan from "components/pages/Laporan";
import FormObservasi from "components/pages/observasi/FormObservasi";
import UnduhanView from "components/pages/UnduhanView";
import TentangAplikasi from "components/pages/TentangAplikasi";
import PrivateRoute from "utils/PrivateRoute";
import DetailLaporan from "components/pages/laporan/DetailLaporan";
import UserList from "components/auth/UserList";
import Setting from "components/pages/Setting";

function App() {
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>

      <PrivateRoute
        path="/setting"
        component={(props) => (
          <Main activeMenu="setting" content={<Setting />} {...props} />
        )}
      />

      <PrivateRoute
        path="/userslist"
        component={(props) => (
          <Main activeMenu="userslist" content={<UserList />} {...props} />
        )}
      />

      <PrivateRoute
        path="/inventarisasi/detail/:id"
        component={(props) => (
          <Main
            activeMenu="inventarisasi"
            content={<DetailInventarisasi />}
            {...props}
          />
        )}
      />

      <PrivateRoute
        path="/inventarisasi"
        component={(props) => (
          <Main
            activeMenu="inventarisasi"
            content={<Inventarisasi />}
            {...props}
          />
        )}
      />

      <PrivateRoute
        path="/kondisi_koleksi"
        component={(props) => (
          <Main
            activeMenu="kondisi_koleksi"
            content={<KondisiKoleksi />}
            {...props}
          />
        )}
      />

      <PrivateRoute
        path="/rencana_observasi"
        component={(props) => (
          <Main
            activeMenu="rencana_observasi"
            content={<Observasi />}
            form={<FormObservasi />}
            {...props}
          />
        )}
      />

      <PrivateRoute
        path="/laporan_observasi/detail/:id"
        component={(props) => (
          <Main
            activeMenu="laporan_observasi"
            content={<DetailLaporan />}
            form={<FormObservasi />}
            {...props}
          />
        )}
      />

      <PrivateRoute
        path="/laporan_observasi"
        component={(props) => (
          <Main
            activeMenu="laporan_observasi"
            content={<Laporan />}
            form={<FormObservasi />}
            {...props}
          />
        )}
      />

      <PrivateRoute
        path="/unduhan"
        component={(props) => (
          <Main activeMenu="unduhan" content={<UnduhanView />} {...props} />
        )}
      />

      <PrivateRoute
        path="/tentang_aplikasi"
        component={(props) => (
          <Main
            activeMenu="tentang_aplikasi"
            content={<TentangAplikasi />}
            {...props}
          />
        )}
      />

      <PrivateRoute path="/">
        <Main activeMenu="beranda" />
      </PrivateRoute>
    </Switch>
  );
}

export default App;
