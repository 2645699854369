import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";

const DropdownFilter = (props) => {
  const [selected, setSelected] = useState("nama_benda");

  const handleChange = (v) => {
    setSelected(v);
  };

  const options = [
    {
      key: "1",
      value: "no_registrasi",
      text: "No.Registrasi",
    },
    {
      key: "2",
      value: "no_inventaris",
      text: "No.Inventaris",
    },
    {
      key: "3",
      value: "nama_benda",
      text: "Nama Benda",
    },
  ];

  return (
    <Dropdown
      {...props}
      options={options}
      item
      selection
      value={selected}
      onChange={(e, f) => {
        handleChange(f.value);
        props.onChange(f.value);
      }}
      style={{ minWidth: 120 }}
    />
  );
};

export default DropdownFilter;
