import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

// Redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./store/reducers";

// Redux Persist
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";

// Semantic UI
import "semantic-ui-css/semantic.min.css";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

// Fonts
import "./fonts/ProximaNova-Thin.otf";
import "./fonts/ProximaNova-Regular.otf";
import "./fonts/ProximaNova-Bold.otf";
import "./fonts/ProximaNova-Extrabold.otf";
import "./fonts/ProximaNova-Black.otf";

// Router
import { BrowserRouter as Router } from "react-router-dom";

// Apollo
import { ApolloProvider } from "@apollo/client";
import { ApolloClient, InMemoryCache } from "@apollo/client";

// Axios
import Axios from "axios";
import { configure } from "axios-hooks";
import LRU from "lru-cache";

// History
// import { createBrowserHistory } from "history";
// import createHistory from "history/createBrowserHistory";
// const history = createHistory();
// const history = createBrowserHistory();

const client = new ApolloClient({
  uri: "https://graphql.musnas.nufaza.com/graphql",
  cache: new InMemoryCache(),
});

// Axios
const axios = Axios.create({
  baseURL: "https://api.musnas.nufaza.com",
});
const cache = new LRU({ max: 10 });
configure({ axios, cache });

// Redux & Persist
const persistConfig = {
  key: "authType",
  storage: storage,
  whitelist: ["authReducer", "uiReducer", "dataReducer"], // which reducer want to store
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk, logger);
const store = createStore(persistedReducer, middleware);
const persistor = persistStore(store);
// export { persistor, store, history };
export { persistor, store };

// Render
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          {/* <Router history={history}> */}
          <Router>
            <App />
          </Router>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
