import React, { useState, useEffect } from "react";
import { toggleSidebarForm } from "store/actions/ui";
import { useDispatch, useSelector } from "react-redux";
import { Progress, Button, Icon, Segment, Tab, Table, Message, Menu, Image } from "semantic-ui-react";
import PlaceholderPara from "components/elements/PlaceholderPara";
import { useWindowHeight } from "@react-hook/window-size";
import { useAxiosWithToken } from "utils/AxiosWithToken";
import { renderDate } from "utils/Lib";
import { API_URL } from "env";

const LaporanObservasi = (props) => {
  // Detect update from state
  const { model, success } = useSelector((state) => state.dataReducer);
  const dispatch = useDispatch();
  const handleBuatRencana = () => {
    dispatch(toggleSidebarForm(true));
  };
  // Paging shit
  const recPerPage = 6;
  const [offset, setOffset] = useState(0);
  const handleNext = () => {
    setOffset(offset + recPerPage);
  };
  const handlePrev = () => {
    setOffset(offset > 0 ? offset - recPerPage : 0);
  };
  const handleRefresh = () => {
    refetch();
  };

  const [{ data, loading, error }, refetch] = useAxiosWithToken(
    // `http://localhost:3333/api/laporan-observasi`
    `/api/laporan-observasi`
  );

  /// UI shit, fixing the viewport
  const { size } = props;
  const screenHeight = useWindowHeight();
  const height = size === "computer" ? screenHeight - 228 : screenHeight - 300;

  // Attempt to do things after form save
  useEffect(() => {
    if (model === "observasi" && success) {
      handleRefresh();
      dispatch(toggleSidebarForm(false));
    }
  }, [model, success]);

  return (
    <Tab.Pane>
      <Menu borderless secondary style={{ paddingLeft: 5 }}>
        {/* <Button icon labelPosition="left" color="brown" onClick={handleBuatRencana}>
          <Icon name="add" />
          Buat Rencana
        </Button> */}
        <Menu.Menu position="right">
          <Menu.Item onClick={handlePrev}>
            <Icon name="angle left" />
          </Menu.Item>
          {/* <Menu.Item onClick={handleRefresh}>
            <Icon name="refresh" />
          </Menu.Item> */}
          <Menu.Item onClick={handleNext}>
            <Icon name="angle right" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {/* <Form.Field inline>
      {/* <Input icon="search" placeholder="Cari.." /> */}
      {/* </Form.Field> */}
      <Segment style={{ padding: 0, overflow: "auto", maxHeight: height }}>
        {error ? <Message>There is an error: {error}</Message> : ""}
        <Table selectable structured celled striped style={{ borderWidth: 0 }} unstackable>
          {size !== "mobile" ? (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell rowSpan="2" width={1}>
                  No.
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2" width={4}>
                  Deskripsi
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2" width={2}>
                  Tenggat
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="3" width={3} textAlign="center">
                  Observasi
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2" width={3}>
                  Progres
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2" width={1} style={{ maxWidth: 28 }}>
                  {" "}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell textAlign="right">Selesai</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Belum</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          ) : (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                <Table.HeaderCell width={4}>Deskripsi</Table.HeaderCell>
                <Table.HeaderCell width={2}>Tenggat</Table.HeaderCell>
                <Table.HeaderCell width={4}>Progres</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2" width={1} style={{ maxWidth: 28 }}>
                  {" "}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}

          {!loading && data ? (
            <Table.Body>
              {/* {data.bendas.map(({ value, key }) => { */}
              {data.map((value, key) =>
                size !== "mobile" ? (
                  <Table.Row>
                    <Table.Cell>{value.no_observasi}</Table.Cell>
                    <Table.Cell>{value.judul_observasi}</Table.Cell>
                    <Table.Cell>{renderDate(value.tanggal_selesai)}</Table.Cell>
                    <Table.Cell textAlign="right">{value.done}</Table.Cell>
                    <Table.Cell textAlign="right">{value.not_done}</Table.Cell>
                    <Table.Cell textAlign="right">{value.all}</Table.Cell>
                    <Table.Cell>
                      <div style={{ float: "left", width: "100%" }}>
                        <Progress style={{ marginBottom: 0 }} success value={value.done} total={value.all} progress="percent" />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <Image
                          as="a"
                          src="icons/excel-file.png"
                          height={25}
                          width={25}
                          onClick={() => {
                            window.open(`${API_URL}/api/laporan-observasi-xls/${value.id_observasi}`, "_blank");
                          }}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <Table.Row key={key}>
                    <Table.Cell>{value.no_observasi}</Table.Cell>
                    <Table.Cell>{value.judul_observasi}</Table.Cell>
                    <Table.Cell>{renderDate(value.tanggal_selesai)}</Table.Cell>
                    <Table.Cell>
                      <div style={{ float: "left", width: "90%" }}>
                        <Progress style={{ marginBottom: 0 }} success value={value.done} total={value.all} progress="percent" />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <Image src="icons/excel-file.png" height={25} width={25} />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          ) : size !== "mobile" ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Segment>
    </Tab.Pane>
  );
};

export default LaporanObservasi;
