export const TOGGLE_SIDEBARMENU = "TOGGLE_SIDEBARMENU";
export const TOGGLE_SIDEBARFORM = "TOGGLE_SIDEBARFORM";
export const TOGGLE_BOTTOMCART = "TOGGLE_BOTTOMCART";
export const TOGGLE_ACTIVEMENU = "TOGGLE_ACTIVEMENU";
export const TOGGLE_ACTIVETAB_OBSERVASI = "TOGGLE_ACTIVETAB_OBSERVASI";
export const TOGGLE_ACTIVETAB_LAPORAN = "TOGGLE_ACTIVETAB_LAPORAN";

export const toggleSidebarMenu = () => {
  return { type: TOGGLE_SIDEBARMENU };
};

export const toggleSidebarForm = (sideBarFormVisible) => {
  return { type: TOGGLE_SIDEBARFORM, sideBarFormVisible: sideBarFormVisible };
};

export const toggleActivemenu = (activeMenu) => {
  return { type: TOGGLE_ACTIVEMENU, activeMenu: activeMenu };
};

export const toggleBottomCart = (bottomCartVisible) => {
  return { type: TOGGLE_BOTTOMCART, bottomCartVisible: bottomCartVisible };
};

export const toggleActivetabObservasi = (activeTabObservasi) => {
  return {
    type: TOGGLE_ACTIVETAB_OBSERVASI,
    activeTabObservasi: activeTabObservasi,
  };
};

export const toggleActivetabLaporan = (activeTabLaporan) => {
  return { type: TOGGLE_ACTIVETAB_LAPORAN, activeTabLaporan: activeTabLaporan };
};
