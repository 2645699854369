import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MediaContextProvider, Media } from "utils/MediaUtils";
import { useWindowHeight } from "@react-hook/window-size";
import {
  Breadcrumb,
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
  Tab,
  Table,
  Sidebar,
  Message,
  Menu,
  Image,
  ImageGroup,
  Divider,
  Label,
  Modal,
} from "semantic-ui-react";
import PelaksanaanObservasi from "./PelaksanaanObservasi";
import FormLaporan from "components/pages/laporan/FormLaporan";
import SidebarForm from "components/elements/SidebarForm";
import LaporanObservasi from "./LaporanObservasi";
import { toggleActivetabLaporan } from "store/actions/ui";
import { useAxiosWithToken } from "utils/AxiosWithToken";
import moment from "moment";
import { toTitleCase } from "utils/Lib";
import Subheader from "components/elements/Subheader";
import { API_URL } from "env";

const apiUrl = API_URL;

const Head = (props) => {
  const sections = [
    { key: "beranda", content: "Beranda", link: true },
    { key: "rencana_observasi", content: "Laporan Observasi", link: true },
    { key: "benda", content: "Mata Uang", link: true },
  ];
  const { value } = props;

  return (
    <div>
      <Breadcrumb icon="right angle" sections={sections} style={{ marginLeft: 20 }} />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        Observasi {toTitleCase(value.nama_benda)}
      </Header>
      <div className="ui" style={{ color: "#ccc", marginTop: 0, marginLeft: 20 }}>
        {value.no_registrasi ? (
          <div>
            No.Registrasi: <b>{value.no_registrasi}</b>;
          </div>
        ) : (
          <div />
        )}
        {value.no_inventaris ? (
          <div>
            No.Inventaris: <b>{value.no_inventaris}</b>;
          </div>
        ) : (
          <div />
        )}
        {value.dimensi_panjang ? (
          <div>
            Panjang: <b>{value.dimensi_panjang} cm</b>;
          </div>
        ) : (
          <div />
        )}
        {value.dimensi_lebar ? (
          <div>
            Lebar: <b>{value.dimensi_lebar} cm</b>;
          </div>
        ) : (
          <div />
        )}
        {value.dimensi_tinggi ? (
          <div>
            Tinggi: <b>{value.dimensi_tinggi} cm</b>;
          </div>
        ) : (
          <div />
        )}
        {value.dimensi_tebal ? (
          <div>
            Tebal: <b>{value.dimensi_tebal} cm</b>;
          </div>
        ) : (
          <div />
        )}
        {value.dimensi_diameter ? (
          <div>
            Diameter: <b>{value.dimensi_diameter} cm</b>;
          </div>
        ) : (
          <div />
        )}
        {value.berat > 0 ? (
          <div>
            Berat: <b>{value.berat} gram</b>;
          </div>
        ) : (
          <div />
        )}
        {value.klasifikasi ? (
          <div>
            Klasifikasi: <b>{value.klasifikasi}</b>;
          </div>
        ) : (
          <div />
        )}
        {value.bahan_koleksi_utama ? (
          <div>
            Bahan Koleksi: <b>{value.bahan_koleksi_utama}</b>;
          </div>
        ) : (
          <div />
        )}
        {value.bahan_koleksi_lainnya ? (
          <div>
            Bahan Koleksi: <b>{value.bahan_koleksi_lainnya}</b>;
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

const Content = (props) => {
  const { id } = useParams();
  const { activeTabLaporan } = useSelector((state) => state.uiReducer);

  const dispatch = useDispatch();
  const handleTabChange = (e, { activeIndex }) => dispatch(toggleActivetabLaporan(activeIndex));

  // const height = useWindowHeight();
  const { size } = props;
  const screenHeight = useWindowHeight();
  const height = size === "computer" ? screenHeight - 100 : screenHeight - 330;

  const { value } = props;

  // State for image selection
  const [mainImageUrl, setMainImageUrl] = useState(`${apiUrl}/uploads/${value.foto_makro1}`);
  const [mainImage, setMainImage] = useState("foto_makro1");
  const [mainImageTitle, setMainImageTitle] = useState("Foto Makro 1");

  const handleChangeImage = (newMainImage, imageUrl, imageTitle) => {
    setMainImage(newMainImage);
    setMainImageUrl(`${apiUrl}/uploads/${imageUrl}`);
    setMainImageTitle(imageTitle);
  };

  // State for image
  const [open, setOpen] = useState(false);

  // Navigation, back
  const history = useHistory();

  var klasifikasi_kerusakan = "";
  switch (value.klasifikasi_kerusakan) {
    case 1:
      klasifikasi_kerusakan = "Tinggi";
      break;
    case 2:
      klasifikasi_kerusakan = "Sedang";
      break;
    case 3:
      klasifikasi_kerusakan = "Rendah";
      break;
  }
  return (
    <Container
      fluid
      style={{
        padding: 10,
        width: "100%",
        maxHeight: height,
        minHeight: height,
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Grid columns={16}>
        <Grid.Column width={14} style={{ paddingBottom: 0 }}>
          <Subheader label="FOTO OBSERVASI" />
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <Icon
            name="close"
            color="grey"
            onClick={() => {
              history.goBack();
            }}
            style={{
              float: "right",
            }}
          />
        </Grid.Column>
      </Grid>
      <Segment
        padded
        textAlign="center"
        style={{
          padding: 10,
          paddingTop: 20,
          width: "100%",
          // backgroundImage: `url("/images/black-dark-vintage-pattern.jpg")`,
        }}
      >
        <Header as="h3">{mainImageTitle}</Header>
        <Modal
          basic
          closeIcon
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          size="small"
          trigger={
            <Image
              size="medium"
              src={mainImageUrl}
              centered
              rounded
              as="a"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/images/img_placeholder.png";
              }}
              onClick={() => {
                setOpen(true);
              }}
            />
          }
        >
          <Header icon>{mainImageTitle}</Header>
          <Modal.Content>
            <Image
              size="huge"
              src={mainImageUrl}
              centered
              rounded
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/images/img_placeholder.png";
              }}
            />
          </Modal.Content>
        </Modal>
        <Divider />
        <ImageGroup>
          <Image
            label="Mikro 1"
            style={mainImage === "foto_mikro1" ? styles.selected : styles.unselected}
            verticalAlign="top"
            rounded
            size="tiny"
            src={`${apiUrl}/uploads/${value.foto_mikro1}`}
            as="a"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/img_placeholder.png";
            }}
            onClick={() => {
              handleChangeImage("foto_mikro1", value.foto_mikro1, "Foto Mikro 1");
            }}
          />
          <Image
            label="Mikro 2"
            style={mainImage === "foto_mikro2" ? styles.selected : styles.unselected}
            verticalAlign="top"
            rounded
            size="tiny"
            src={`${apiUrl}/uploads/${value.foto_mikro2}`}
            as="a"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/img_placeholder.png";
            }}
            onClick={() => {
              handleChangeImage("foto_mikro2", value.foto_mikro2, "Foto Mikro 2");
            }}
          />
          <Image
            label="Makro 1"
            style={mainImage === "foto_makro1" ? styles.selected : styles.unselected}
            verticalAlign="top"
            rounded
            size="tiny"
            src={`${apiUrl}/uploads/${value.foto_makro1}`}
            as="a"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/img_placeholder.png";
            }}
            onClick={() => {
              handleChangeImage("foto_makro1", value.foto_makro1, "Foto Makro 1");
            }}
          />
          <Image
            label="Makro 2"
            style={mainImage === "foto_makro2" ? styles.selected : styles.unselected}
            verticalAlign="top"
            rounded
            size="tiny"
            src={`${apiUrl}/uploads/${value.foto_makro2}`}
            as="a"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/img_placeholder.png";
            }}
            onClick={() => {
              handleChangeImage("foto_makro2", value.foto_makro2, "Foto Makro 2");
            }}
          />
          <Image
            label="Makro 3"
            style={mainImage === "foto_makro3" ? styles.selected : styles.unselected}
            verticalAlign="top"
            rounded
            size="tiny"
            src={`${apiUrl}/uploads/${value.foto_makro3}`}
            as="a"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/img_placeholder.png";
            }}
            onClick={() => {
              handleChangeImage("foto_makro3", value.foto_makro3, "Foto Makro 3");
            }}
          />
          <Image
            label="Makro 4"
            style={mainImage === "foto_makro4" ? styles.selected : styles.unselected}
            verticalAlign="top"
            rounded
            size="tiny"
            src={`${apiUrl}/uploads/${value.foto_makro4}`}
            as="a"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/img_placeholder.png";
            }}
            onClick={() => {
              handleChangeImage("foto_makro4", value.foto_makro4, "Foto Makro 4");
            }}
          />
        </ImageGroup>
      </Segment>
      <Subheader label="DATA OBSERVASI" />
      <Segment>
        {/* {toTitleCase(value.nama_benda)} */}
        <Container textAlign="left" style={{ padding: 20 }}>
          <Grid columns="2">
            <Grid.Column>
              <Header as="h5">Person in Charge</Header>
              <div>{value.pics}</div>
              <Header as="h5">Pengukuran</Header>
              <div>
                {value.dimensi_tinggi ? `Tinggi: ${value.dimensi_tinggi}cm,  ` : ""}
                {value.dimensi_panjang ? `Panjang: ${value.dimensi_panjang}cm,  ` : ""}
                {value.dimensi_lebar ? `Lebar: ${value.dimensi_lebar}cm,  ` : ""}
                {value.dimensi_diameter ? `Diameter: ${value.dimensi_diameter}cm,  ` : ""}
                {value.dimensi_tebal ? `Tebal: ${value.dimensi_tebal}cm,  ` : ""}
                {value.berat ? `Berat: ${value.berat}gr  ` : ""}
              </div>
              <Header as="h5">Pengamatan Bahan</Header>
              <div>{value.bahan_utama ? `Bahan utama : ${value.bahan_utama}  ` : ""}</div>
              <div>{value.bahan_lainnya ? `Bahan lainnya : ${value.bahan_lainnya}  ` : ""}</div>

              <Header as="h5">Waktu Observasi</Header>
              <div>{moment(value.waktu_observasi).format("DD/MM/YYYY")}</div>
              <Header as="h5">Lingkungan Mikro</Header>
              <div>{value.lingkungan_mikro}</div>
            </Grid.Column>
            <Grid.Column>
              {/* <Header as="h5">Pelaksana Observasi</Header>
              <div>
                {value.nama_pelaksana1} dan {value.nama_pelaksana2}
              </div> */}
              <Header as="h5">Klasifikasi Kerusakan</Header>
              <div>{klasifikasi_kerusakan}</div>
              <Header as="h5">Bentuk Kerusakan</Header>
              <div>{value.keadaan_koleksi}</div>
              <Header as="h5">Keadaan Koleksi</Header>
              <div>{value.rekomendasi_perawatan}</div>
              <Header as="h5">Keadaan Koleksi</Header>
              <div>{value.rekomendasi_pengawetan}</div>
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
    </Container>
  );
};

const styles = {
  selected: {
    borderBottomColor: "green",
    borderBottomWidth: "3",
    borderBottomStyle: "solid",
  },
  unselected: {},
};

const DetailLaporan = () => {
  const { id } = useParams();
  // return <div>Showing {id}</div>;

  const { sideBarFormVisible } = useSelector((state) => state.uiReducer);

  // const height = useWindowHeight() - 168;
  const height = useWindowHeight();
  console.log(height);

  // const addr = `http://localhost:3333/api/observasi-rinci/${id}`;
  const addr = `/api/observasi-rinci/${id}`;
  console.log(addr);

  const [{ data, loading, error }, refetch] = useAxiosWithToken(addr);

  if (data) {
    console.log(data);
  }

  const value = data ? data[0] : null;
  if (!data) return "no data found";

  return (
    <div>
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <Sidebar.Pushable>
        <MediaContextProvider>
          <Media between={["mobile", "tablet"]}>
            <Sidebar.Pushable>
              <SidebarForm size="mobile">
                <FormLaporan />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <div
                  style={{
                    backgroundColor: "#eee",
                    minHeight: height - 70,
                    maxHeight: height - 80,
                  }}
                >
                  <Grid columns={1} style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column
                      style={{
                        height: "20%",
                        backgroundImage: `url(${"/images/brown_bg_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Head value={value} />
                    </Grid.Column>
                  </Grid>
                  <Content value={value} size="mobile" />
                </div>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
          <Media at="tablet">
            <Sidebar.Pushable>
              <SidebarForm size="computer">
                <FormLaporan />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <div
                  style={{
                    backgroundColor: "#eee",
                    minHeight: height - 70,
                    maxHeight: height - 80,
                  }}
                >
                  <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
                    <Grid.Column
                      style={{
                        height: "20%",
                        backgroundImage: `url(${"/images/brown_bg_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Head value={value} />
                    </Grid.Column>
                  </Grid>
                  <Content value={value} size="tablet" />
                </div>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
          <Media greaterThanOrEqual="computer">
            <Sidebar.Pushable>
              <SidebarForm size="computer">
                <FormLaporan />
              </SidebarForm>
              <Sidebar.Pusher dimmed={sideBarFormVisible}>
                <Grid columns={2}>
                  <Grid.Row stretched>
                    <Grid.Column
                      stretched
                      width={6}
                      verticalAlign="middle"
                      style={{
                        height: "100%",
                        backgroundImage: `url(${"/images/brown_bg_blur.jpg"})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <Grid stretched>
                        <Grid.Column stretched verticalAlign="middle">
                          <Head value={value} />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      style={{
                        backgroundColor: "#eee",
                        minHeight: height - 87,
                        maxHeight: height - 87,
                      }}
                    >
                      <Content value={value} size="computer" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Media>
        </MediaContextProvider>
      </Sidebar.Pushable>
    </div>
  );
};

export default DetailLaporan;
