import React, { useState } from "react";
import {
  Breadcrumb,
  Grid,
  Header,
  Button,
  Icon,
  Segment,
  Table,
  Message,
  Menu,
  Image,
} from "semantic-ui-react";
import { MediaContextProvider, Media } from "utils/MediaUtils";
import { useWindowHeight } from "@react-hook/window-size";

import PlaceholderPara from "components/elements/PlaceholderPara";

import { useAxiosWithToken } from "utils/AxiosWithToken";
import ProfileForm from "./ProfileForm";

// const apiServer = `https://papi.musnas.nufaza.com`;
// const apiServer = `http://localhost:3333`;

const Head = () => {
  const sections = [
    { key: "beranda", content: "Beranda", link: true },
    { key: "inventarisasi", content: "Inventarisasi", link: true },
  ];
  return (
    <div>
      <Breadcrumb
        icon="right angle"
        sections={sections}
        style={{ marginLeft: 20, style: "bold" }}
      />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        Daftar Pengguna
      </Header>
    </div>
  );
};

const Content = () => {
  // Paging shit
  const recPerPage = 6;
  const [offset, setOffset] = useState(0);
  const handleNext = () => {
    setOffset(offset + recPerPage);
  };
  const handlePrev = () => {
    setOffset(offset > 0 ? offset - recPerPage : 0);
  };
  const handleRefresh = () => {
    refetch();
  };

  var url = `/api/users`;
  const [{ data, loading, error }, refetch] = useAxiosWithToken(url);

  const height = useWindowHeight();
  const handleBuatPengguna = () => {
    setUserProfile({
      id: "",
      username: "",
      email: "",
      password: "",
      realm: "",
      picture: "",
    });
    setShowProfile(true);
  };

  // Modal management
  const [showProfile, setShowProfile] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const handleShowProfile = (userProfile) => {
    console.log(userProfile);
    setUserProfile(userProfile);
    setShowProfile(true);
  };

  return (
    <div style={{ paddingTop: 10 }}>
      <Segment>
        <Menu borderless secondary style={{ paddingLeft: 5 }}>
          <Button
            icon
            labelPosition="left"
            color="brown"
            onClick={handleBuatPengguna}
          >
            <Icon name="add" />
            Tambah Pengguna
          </Button>
          <Menu.Menu position="right">
            <Menu.Item onClick={handlePrev}>
              <Icon name="angle left" />
            </Menu.Item>
            {/* <Menu.Item onClick={handleRefresh}>
            <Icon name="refresh" />
          </Menu.Item> */}
            <Menu.Item onClick={handleNext}>
              <Icon name="angle right" />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        {/* <Form.Field inline>
      {/* <Input icon="search" placeholder="Cari.." /> */}
        {/* </Form.Field> */}
        <Segment style={{ padding: 0, overflow: "auto", maxHeight: height }}>
          {error ? <Message>There is an error: {error}</Message> : ""}
          <Table
            selectable
            structured
            celled
            striped
            style={{ borderWidth: 0 }}
            unstackable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>No.</Table.HeaderCell>
                <Table.HeaderCell>Nama</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Peran</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {!loading && data ? (
              <Table.Body>
                {data.map((value, key) => (
                  <Table.Row onClick={() => handleShowProfile(value)}>
                    <Table.Cell>{key + 1}</Table.Cell>
                    <Table.Cell>{value.username}</Table.Cell>
                    <Table.Cell>{value.email}</Table.Cell>
                    <Table.Cell>{value.realm}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            ) : (
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                  <Table.Cell>
                    <PlaceholderPara />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
          </Table>
        </Segment>
      </Segment>
      <ProfileForm
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        userProfile={userProfile}
        handleRefresh={handleRefresh}
      />
    </div>
  );
};
const UsersList = () => {
  const height = useWindowHeight();
  return (
    <div>
      {/* <div style={{ backgroundColor: "#eee", width }}/> */}
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <MediaContextProvider>
        <Media between={["mobile", "tablet"]}>
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height - 70,
              maxHeight: height - 80,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/foto_museum_pegawai_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <div style={{ overflow: "scroll", maxHeight: height - 100 }}>
              <Content />
            </div>
          </div>
        </Media>
        <Media at="tablet">
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height,
              maxHeight: height,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/foto_museum_pegawai_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <Content />
          </div>
        </Media>
        <Media greaterThanOrEqual="computer">
          <Grid columns={2} style={{ height: "90vh" }}>
            <Grid.Row stretched>
              <Grid.Column
                stretched
                width={6}
                // textAlign="center"
                verticalAlign="middle"
                style={{
                  height: "100%",
                  backgroundImage: `url(${"images/foto_museum_pegawai_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  // marginTop: 15,
                }}
              >
                <Grid stretched>
                  <Grid.Column stretched verticalAlign="middle">
                    <Head />
                    {/* <Breadcrumb icon="right angle" sections={sections} style={{ marginLeft: 20 }} />
                <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
                  Aplikasi Database Koleksi
                </Header> */}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column width={10} style={{ backgroundColor: "#eee" }}>
                <Content />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
      {/* <Header as="h3">Dashboard</Header>
  <Image src="/images/paragraph.png" /> */}
    </div>
  );
};

export default UsersList;
