import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  // Dropdown,
  Form,
  // Grid,
  // GridColumn,
  Header,
  Icon,
  Input,
  // Input,
  Menu,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { toggleSidebarForm } from "store/actions/ui";
import DropdownAsalPermintaan from "components/dropdowns/DropdownAsalPermintaan";
import { EAPI_URL, post, toggleIsLoading } from "store/actions/data";
import moment from "moment";
import { generateId } from "utils/Lib";
import ImageUploader from "react-images-upload";
import { axiosWithToken } from "utils/AxiosWithToken";
import DropdownBentukKerusakan from "components/dropdowns/DropdownBentukKerusakan";
import DropdownPenyebabKerusakan from "components/dropdowns/DropdownPenyebabKerusakan";
import "./formLaporan.css";
import DropdownKlasifikasiKerusakan from "components/dropdowns/DropdownKlasifikasiKerusakan";
import DropdownBahanKoleksi from "components/dropdowns/DropdownBahanKoleksi";

// const apiServer = `https://papi.musnas.nufaza.com`;

const FormLaporan = (props) => {
  // Redux states
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);

  // Ui state
  const { idObservasiRinci, obyekObservasiRinci, deskripsiObyekObservasi, isLoading } = useSelector((state) => state.dataReducer);

  // Form states
  const [formValues, setFormValues] = useState({
    // id_observasi_rinci: "",
    // id_observasi: "",
    // id_benda: "",
    is_organik: false,
    is_anorganik: false,
    dimensi_tinggi: 0,
    id_satuan_tinggi: "",
    dimensi_panjang: 0,
    id_satuan_panjang: "",
    dimensi_lebar: 0,
    id_satuan_lebar: "",
    dimensi_diameter: 0,
    id_satuan_diameter: "",
    dimensi_tebal: 0,
    id_satuan_tebal: "",
    berat: "",
    id_satuan_berat: "",
    id_bahan_utama: "",
    id_bahan_lainnya: "",
    lingkungan_mikro: "",
    klasifikasi_kerusakan: "",
    keadaan_koleksi: "",
    rekomendasi_perawatan: "",
    rekomendasi_pengawetan: "",
    foto_mikro1: [],
    foto_mikro2: [],
    foto_makro1: [],
    foto_makro2: [],
    foto_makro3: [],
    foto_makro4: [],
    waktu_observasi: "",
  });

  const [formValidationMessage, setFormValidationMessage] = useState({
    // id_observasi_rinci: "",
    // id_observasi: "",
    // id_benda: "",
    is_organik: "",
    is_anorganik: "",
    dimensi_tinggi: "",
    id_satuan_tinggi: "",
    dimensi_panjang: "",
    id_satuan_panjang: "",
    dimensi_lebar: "",
    id_satuan_lebar: "",
    dimensi_diameter: "",
    id_satuan_diameter: "",
    dimensi_tebal: "",
    id_satuan_tebal: "",
    berat: "",
    id_satuan_berat: "",
    id_bahan_utama: "",
    id_bahan_lainnya: "",
    lingkungan_mikro: "",
    klasifikasi_kerusakan: "",
    keadaan_koleksi: "",
    rekomendasi_perawatan: "",
    rekomendasi_pengawetan: "",
    foto_mikro1: [],
    foto_mikro2: [],
    foto_makro1: [],
    foto_makro2: [],
    foto_makro3: [],
    foto_makro4: [],
    waktu_observasi: "",
  });

  const [formValidity, setFormValidity] = useState({
    // id_observasi_rinci: true,
    // id_observasi: true,
    // id_benda: true,
    is_organik: true,
    is_anorganik: true,
    dimensi_tinggi: true,
    id_satuan_tinggi: true,
    dimensi_panjang: true,
    id_satuan_panjang: true,
    dimensi_lebar: true,
    id_satuan_lebar: true,
    dimensi_diameter: true,
    id_satuan_diameter: true,
    dimensi_tebal: true,
    id_satuan_tebal: true,
    berat: true,
    id_satuan_berat: true,
    id_bahan_utama: true,
    id_bahan_lainnya: true,
    lingkungan_mikro: true,
    klasifikasi_kerusakan: true,
    keadaan_koleksi: true,
    rekomendasi_perawatan: true,
    rekomendasi_pengawetan: true,
    foto_mikro1: true,
    foto_mikro2: true,
    foto_makro1: true,
    foto_makro2: true,
    foto_makro3: true,
    foto_makro4: true,
    waktu_observasi: true,
  });

  // Hide sidebar upon loading to save
  useEffect(() => {
    if (isLoading === false) {
      dispatch(toggleSidebarForm(false));
    }
  }, [isLoading]);

  // Hide sidebar upon cancel
  const handleCancel = () => {
    dispatch(toggleSidebarForm(false));
  };

  const handleSave = () => {
    // Convert tanggal to string
    // let idObservasi = generateId("T", "==", 21);
    // let tanggalMulai = moment(formValues.tanggalMulai).format("DD/MM/YYYY");
    // let tanggalSelesai = moment(formValues.tanggalSelesai).format("DD/MM/YYYY");
    // let output = { ...formValues, tanggalMulai, tanggalSelesai };
    // console.log(output);
    // Call redux action
    console.log(formValues);
    // return;

    let month = moment(Date.now()).format("YYYYMM");
    let waktu_observasi = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");

    var formData = new FormData();
    formData.append("id_observasi_rinci", idObservasiRinci);

    formData.append("is_organik", formValues.is_organik);
    formData.append("is_anorganik", formValues.is_anorganik);
    formData.append("dimensi_tinggi", formValues.dimensi_tinggi);
    formData.append("id_satuan_tinggi", formValues.id_satuan_tinggi);
    formData.append("dimensi_panjang", formValues.dimensi_panjang);
    formData.append("id_satuan_panjang", formValues.id_satuan_panjang);
    formData.append("dimensi_lebar", formValues.dimensi_lebar);
    formData.append("id_satuan_lebar", formValues.id_satuan_lebar);
    formData.append("dimensi_diameter", formValues.dimensi_diameter);
    formData.append("id_satuan_diameter", formValues.id_satuan_diameter);
    formData.append("dimensi_tebal", formValues.dimensi_tebal);
    formData.append("id_satuan_tebal", formValues.id_satuan_tebal);
    formData.append("berat", formValues.berat);
    formData.append("id_satuan_berat", formValues.id_satuan_berat);
    formData.append("id_bahan_utama", formValues.id_bahan_utama);
    formData.append("id_bahan_lainnya", formValues.id_bahan_lainnya);
    formData.append("lingkungan_mikro", formValues.lingkungan_mikro);
    formData.append("klasifikasi_kerusakan", formValues.klasifikasi_kerusakan);
    formData.append("keadaan_koleksi", formValues.keadaan_koleksi);
    formData.append("rekomendasi_perawatan", formValues.rekomendasi_perawatan);
    formData.append("rekomendasi_pengawetan", formValues.rekomendasi_pengawetan);
    formData.append("foto_mikro1", formValues.foto_mikro1);
    formData.append("foto_mikro2", formValues.foto_mikro2);
    formData.append("foto_makro1", formValues.foto_makro1);
    formData.append("foto_makro2", formValues.foto_makro2);
    formData.append("foto_makro3", formValues.foto_makro3);
    formData.append("foto_makro4", formValues.foto_makro4);
    formData.append("waktu_observasi", waktu_observasi);
    formData.append("month", month);

    console.log(formValues);
    // console.log(formData);

    let url = "/upload";

    axiosWithToken
      .post(url, formData, {})
      .then((res) => {
        dispatch(toggleIsLoading(false));
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch(toggleIsLoading(true));
    // dispatch(post("observasis", output, auth));
  };

  // Handle change for non ordinary text input
  const handleChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (name) => {
    console.log("Form values: ", formValues);
    console.log(`${name} value: `, formValues[name]);
    console.log("nama field: ", name, "nilai:", formValues[name], "berubah jadi:", !formValues[name]);

    setFormValues({
      ...formValues,
      [name]: !formValues[name],
    });
  };

  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  // Handle change for ordinary text input
  const change = (e) => {
    console.log(e);
    console.log(e.target.value);

    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    // console.log(formValues);
  };

  const handleChooseImage = (name, image) => {
    setFormValues({
      ...formValues,
      [name]: image,
    });
  };

  // const [pic, setPic] = useState([]);
  // const onDrop = (upload) => {
  //   console.log(upload);
  //   setPic(pic.concat(upload));
  // };

  // Handle validate for non ordinary text input
  const handleValidate = (name) => {
    var isValid = true;
    var message = "";
    switch (name) {
      default:
        isValid = formValues[name] !== "";
        message = name + " tidak boleh kosong";
        break;
    }
    setFormValidity({
      ...formValidity,
      [name]: isValid,
    });
    if (!isValid) {
      setFormValidationMessage({
        ...formValidationMessage,
        [name]: message,
      });
    }
  };

  const handleDummy = () => {
    setFormValues({
      ...formValues,
      is_organik: false,
      is_anorganik: true,
      lingkungan_mikro: "-",
      // dimensi_tinggi: 20,
      // id_satuan_tinggi: 1,
      // dimensi_panjang: 5,
      // id_satuan_panjang: 1,
      // dimensi_lebar: 9,
      // id_satuan_lebar: 1,
      // dimensi_diameter: 2,
      // id_satuan_diameter: 1,
      // dimensi_tebal: 1,
      // id_satuan_tebal: 1,
      // berat: 10,
      // id_satuan_berat: 3,
      id_bahan_utama: 6,
      id_bahan_lainnya: "",
      klasifikasi_kerusakan: 3,
      keadaan_koleksi: "Noda hitam dan goresan, korosi merah pada bagian belakang, infestasi jamur.",
      rekomendasi_perawatan: "Perawatan tingkat dasar, perawatan tingkat lanjut untuk menghilangkan debu, noda hitam/putih, dan korosi",
      rekomendasi_pengawetan: "Pelapisan secara tipis, pembungkusan dengan silica gel",
      foto_mikro1: "",
      foto_mikro2: "",
      foto_makro1: "",
      foto_makro2: "",
      foto_makro3: "",
      foto_makro4: "",
      waktu_observasi: moment().toDate(),
    });
  };

  // Handle validate for ordinary text input
  const validate = (e) => {
    var isValid = true;
    var message = "";
    switch (e.target.name) {
      default:
        isValid = e.target.value !== "";
        message = e.target.name + " tidak boleh kosong";
        break;
    }
    setFormValidity({
      ...formValidity,
      [e.target.name]: isValid,
    });
    if (!isValid) {
      setFormValidationMessage({
        ...formValidationMessage,
        [e.target.name]: message,
      });
    }
  };

  useEffect(() => {
    // console.log("Effect kicked in FormLaporan: ", obyekObservasiRinci);
    if (typeof obyekObservasiRinci.id_observasi_rinci !== "undefined") {
      setFormValues({
        ...formValues,
        is_organik: typeof obyekObservasiRinci.is_organik !== undefined ? obyekObservasiRinci.is_organik : false,
        is_anorganik: typeof obyekObservasiRinci.is_anorganik !== undefined ? obyekObservasiRinci.is_anorganik : false,
        dimensi_tinggi: obyekObservasiRinci.dimensi_tinggi ? obyekObservasiRinci.dimensi_tinggi : "",
        dimensi_panjang: obyekObservasiRinci.dimensi_panjang ? obyekObservasiRinci.dimensi_panjang : "",
        dimensi_lebar: obyekObservasiRinci.dimensi_lebar ? obyekObservasiRinci.dimensi_lebar : "",
        dimensi_diameter: obyekObservasiRinci.dimensi_diameter ? obyekObservasiRinci.dimensi_diameter : "",
        dimensi_tebal: obyekObservasiRinci.dimensi_tebal ? obyekObservasiRinci.dimensi_tebal : "",
        lingkungan_mikro: typeof obyekObservasiRinci.lingkungan_mikro !== undefined ? obyekObservasiRinci.lingkungan_mikro : false,
        klasifikasi_kerusakan: obyekObservasiRinci.klasifikasi_kerusakan ? obyekObservasiRinci.klasifikasi_kerusakan : "",
        keadaan_koleksi: obyekObservasiRinci.keadaan_koleksi ? obyekObservasiRinci.keadaan_koleksi : "",
        rekomendasi_perawatan: obyekObservasiRinci.rekomendasi_perawatan ? obyekObservasiRinci.rekomendasi_perawatan : "",
        rekomendasi_pengawetan: obyekObservasiRinci.rekomendasi_pengawetan ? obyekObservasiRinci.rekomendasi_pengawetan : "",
        waktu_observasi: obyekObservasiRinci.waktu_observasi ? obyekObservasiRinci.waktu_observasi : "",
      });
    }
  }, [obyekObservasiRinci]);

  // const checkValid = () => {
  //   console.log();
  //   var vals = Object.keys(formValues).map((key) => formValues[key]);
  //   return vals.reduce((accValue, currValue) => accValue && currValue);
  // };

  // const options = [
  //   {
  //     key: "1",
  //     value: "1",
  //     text: "Konservator",
  //   },
  //   {
  //     key: "2",
  //     value: "2",
  //     text: "Pesanan Pejabat",
  //   },
  //   {
  //     key: "3",
  //     value: "3",
  //     text: "Bidang Konservasi",
  //   },
  //   {
  //     key: "4",
  //     value: "4",
  //     text: "Bidang Registrasi",
  //   },
  // ];

  return (
    <Container>
      <Menu secondary borderless>
        <Header as="h3" style={{ paddingTop: 20, paddingLeft: 20, marginBottom: 5 }}>
          Form Laporan Observasi
        </Header>
        <Menu.Menu position="right">
          <Menu.Item position="right" active={false} onClick={handleCancel}>
            <Icon name="close" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Divider fitted />
      <div style={{ padding: 10, paddingLeft: 15 }}>
        <b>Obyek Observasi :</b>
        <br />
        {deskripsiObyekObservasi}
      </div>
      <Divider fitted />
      <Form style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}>
        {/* 
          berat
          id_satuan_berat
          lingkungan_mikro
          klasifikasi_kerusakan
                   
        */}
        <Form.Group widths="equal">
          <Form.Field>
            <label>Bahan Koleksi</label>
            <Checkbox
              label="Organik"
              name="is_organik"
              checked={formValues.is_organik}
              onChange={() => {
                handleCheckboxChange("is_organik");
              }}
              onBlur={validate}
              style={{ marginRight: 0 }}
            />
            <Checkbox
              label="Anorganik"
              name="is_anorganik"
              checked={formValues.is_anorganik}
              onChange={() => {
                handleCheckboxChange("is_anorganik");
              }}
              onBlur={validate}
              style={{ marginRight: 0 }}
            />
          </Form.Field>
          <Form.Field>
            <label>Bahan Utama</label>
            <DropdownBahanKoleksi fluid name="id_bahan_utama" onChange={(v) => handleChange("id_bahan_utama", v)} value={formValues.id_bahan_utama} />
          </Form.Field>
          <Form.Field>
            <label>Bahan Lainnya</label>
            <DropdownBahanKoleksi
              fluid
              name="id_bahan_lainnya"
              onChange={(v) => handleChange("id_bahan_lainnya", v)}
              value={formValues.id_bahan_lainnya}
            />
          </Form.Field>
          {/* <Form.Field>
            <label>Ukuran</label>
            <Checkbox
              label="Lingkungan Mikro"
              name="lingkungan_mikro"
              checked={formValues.lingkungan_mikro}
              onChange={() => {
                handleCheckboxChange("lingkungan_mikro");
              }}
              onBlur={validate}
              style={{ marginRight: 10 }}
            />
          </Form.Field> */}
        </Form.Group>
        <Form.Group>
          <Form.Input
            label="Tinggi (cm)"
            name="dimensi_tinggi"
            value={formValues.dimensi_tinggi}
            type="number"
            onChange={change}
            onBlur={validate}
            style={{ width: 75 }}
          />
          <Form.Input
            label="Panjang (cm)"
            name="dimensi_panjang"
            value={formValues.dimensi_panjang}
            type="number"
            onChange={change}
            onBlur={validate}
            style={{ width: 78 }}
          />
          <Form.Input
            label="Lebar (cm)"
            name="dimensi_lebar"
            value={formValues.dimensi_lebar}
            type="number"
            onChange={change}
            onBlur={validate}
            style={{ width: 75 }}
          />
          <Form.Input
            label="Diamtr (cm)"
            name="dimensi_diameter"
            value={formValues.dimensi_diameter}
            type="number"
            onChange={change}
            onBlur={validate}
            style={{ width: 75 }}
          />
          <Form.Input
            label="Tebal (cm)"
            name="dimensi_tebal"
            value={formValues.dimensi_tebal}
            type="number"
            onChange={change}
            onBlur={validate}
            style={{ width: 75 }}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input label="Berat (gr)" name="berat" value={formValues.berat} type="number" onChange={change} onBlur={validate} fluid />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Lingkungan Mikro"
              name="lingkungan_mikro"
              value={formValues.lingkungan_mikro}
              type="text"
              onChange={change}
              onBlur={validate}
              fluid
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <SemanticDatepicker
              icon="calendar"
              label="Waktu Observasi"
              name="waktu_observasi"
              format="DD/MM/YYYY"
              // error={!formValidity.waktu_observasi}
              value={formValues.waktu_observasi}
              // onChange={handleChange}
              onChange={(e, f) => handleChange(f.name, f.value)}
              onBlur={() => handleValidate("waktu_observasi")}
              placeholder="Pilih tanggal..."
            />
          </Form.Field>
          <Form.Field>
            <label>Klasifikasi Kerusakan</label>
            <DropdownKlasifikasiKerusakan
              label="Klasifikasi Kerusakan"
              name="klasifikasi_kerusakan"
              onChange={(v) => handleChange("klasifikasi_kerusakan", v)}
              value={formValues.klasifikasi_kerusakan}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Form.TextArea
            // fluid
            label="Keadaan Koleksi"
            name="keadaan_koleksi"
            value={formValues.keadaan_koleksi}
            onChange={change}
            onBlur={validate}
            placeholder="Keadaan Koleksi"
            rows={2}
          />
        </Form.Field>
        <Form.Field>
          <Form.TextArea
            // fluid
            label="Rekomendasi Perawatan"
            name="rekomendasi_perawatan"
            value={formValues.rekomendasi_perawatan}
            onChange={change}
            onBlur={validate}
            placeholder="Rekomendasi Perawatan"
            rows={2}
          />
        </Form.Field>
        <Form.Field>
          <Form.TextArea
            // fluid
            label="Rekomendasi Pengawetan"
            name="rekomendasi_pengawetan"
            value={formValues.rekomendasi_pengawetan}
            onChange={change}
            onBlur={validate}
            placeholder="Rekomendasi Pengawetan"
            rows={2}
          />
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field>
            <label>Foto Mikro 1</label>
            <ImageUploader
              withPreview={true}
              withIcon={false}
              buttonText="Pilih foto"
              withLabel={false}
              onChange={(pic) => {
                handleChooseImage("foto_mikro1", pic[0]);
              }}
              imgExtension={[".jpg"]}
              maxFileSize={5242880}
              singleImage={true}
              name="foto_mikro1"
            />
          </Form.Field>
          <Form.Field>
            <label>Foto Mikro 2</label>
            <ImageUploader
              withPreview={true}
              withIcon={false}
              buttonText="Pilih foto"
              withLabel={false}
              onChange={(pic) => {
                handleChooseImage("foto_mikro2", pic[0]);
              }}
              imgExtension={[".jpg"]}
              maxFileSize={5242880}
              singleImage={true}
              name="foto_mikro2"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Foto Makro 1</label>
            <ImageUploader
              withPreview={true}
              withIcon={false}
              buttonText="Pilih foto"
              withLabel={false}
              onChange={(pic) => {
                handleChooseImage("foto_makro1", pic[0]);
              }}
              imgExtension={[".jpg"]}
              maxFileSize={5242880}
              singleImage={true}
              name="foto_makro1"
            />
          </Form.Field>
          <Form.Field>
            <label>Foto Makro 2</label>
            <ImageUploader
              withPreview={true}
              withIcon={false}
              buttonText="Pilih foto"
              withLabel={false}
              onChange={(pic) => {
                handleChooseImage("foto_makro2", pic[0]);
              }}
              imgExtension={[".jpg"]}
              maxFileSize={5242880}
              singleImage={true}
              name="foto_makro2"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Foto Makro 3</label>
            <ImageUploader
              withPreview={true}
              withIcon={false}
              buttonText="Pilih foto"
              withLabel={false}
              onChange={(pic) => {
                handleChooseImage("foto_makro3", pic[0]);
              }}
              imgExtension={[".jpg"]}
              maxFileSize={5242880}
              singleImage={true}
              name="foto_makro3"
            />
          </Form.Field>
          <Form.Field>
            <label>Foto Makro 4</label>
            <ImageUploader
              withPreview={true}
              withIcon={false}
              buttonText="Pilih foto"
              withLabel={false}
              onChange={(pic) => {
                handleChooseImage("foto_makro4", pic[0]);
              }}
              imgExtension={[".jpg"]}
              maxFileSize={5242880}
              singleImage={true}
              name="foto_makro4"
            />
          </Form.Field>
        </Form.Group>
        <Button type="submit" primary onClick={handleSave} loading={isLoading}>
          Simpan
        </Button>
        <Button type="cancel" onClick={handleCancel}>
          Batal
        </Button>
        <Button
          type="cancel"
          onClick={handleDummy}
          style={{
            float: "right",
          }}
        >
          Dummy
        </Button>
      </Form>
    </Container>
  );
};

export default FormLaporan;
