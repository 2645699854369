import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { MediaContextProvider, Media } from "utils/MediaUtils";
import { useWindowHeight } from "@react-hook/window-size";

import {
  Breadcrumb,
  Container,
  Form,
  Grid,
  Header,
  Input,
  Segment,
  Tab,
  Table,
} from "semantic-ui-react";
// import Subheader from "components/elements/Subheader";
// import StatNumber from "components/elements/StatNumber";
import PlaceholderPara from "components/elements/PlaceholderPara";

const Head = (props) => {
  const sections = [
    { key: "beranda", content: "Beranda", link: true },
    { key: "kondisi_koleksi", content: "Kondisi Koleksi", link: true },
  ];
  return (
    <div>
      <Breadcrumb
        icon="right angle"
        sections={sections}
        style={{ marginLeft: 20 }}
      />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        Kondisi Koleksi
      </Header>
    </div>
  );
};
/*
const Migrasi = () => {
  return (
    <Tab.Pane>
      <Grid columns={1} stretched={true}>
        <Grid.Column verticalAlign="middle" textAlign="center">
          <Image centered src="/images/server_transfer.png" />
        </Grid.Column>
      </Grid>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <StatNumber icon="check" number={192912} label="Data Master" />
          </Grid.Column>
          <Grid.Column verticalAlign="middle" textAlign="center">
            <Button primary>Sinkronisasi</Button>
          </Grid.Column>
          <Grid.Column>
            <StatNumber icon="check" number={162212} label="Data Slave" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};
*/

const LIST_BENDAS = gql`
  query ListBendas {
    bendas(filter: { limit: 15 }) {
      idBenda
      namaBenda
      noInventaris
      noRegistrasi
      idKondisi
    }
  }
`;

const Data = (props) => {
  const { size } = props;
  const { loading, error, data } = useQuery(LIST_BENDAS);
  const screenHeight = useWindowHeight();
  const height = size === "computer" ? screenHeight - 210 : screenHeight - 280;

  console.log(error);
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error :</p>;

  console.log(data);
  console.log(size);

  var kondisi = [];
  kondisi["1"] = "Baik";
  kondisi["2"] = "Rusak Ringan";
  kondisi["3"] = "Rusak Sedang";
  kondisi["4"] = "Rusak Berat";
  kondisi["5"] = "Rusak Total";

  return (
    <Tab.Pane>
      <Form.Field inline>
        <Input icon="search" placeholder="Cari.." />
      </Form.Field>
      <Segment
        style={{
          padding: 0,
          overflow: "auto",
          minHeight: height,
          maxHeight: height,
        }}
      >
        <Table striped style={{ borderWidth: 0 }} unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>No.Reg</Table.HeaderCell>
              <Table.HeaderCell width={4}>Nama</Table.HeaderCell>
              <Table.HeaderCell width={4}>No.Inv</Table.HeaderCell>
              <Table.HeaderCell width={4}>Kondisi</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {!loading && data ? (
            <Table.Body>
              {/* {data.bendas.map(({ value, key }) => { */}
              {data.bendas.map((value, key) => (
                <Table.Row>
                  <Table.Cell>{value.noRegistrasi}</Table.Cell>
                  <Table.Cell>{value.namaBenda}</Table.Cell>
                  <Table.Cell>{value.noInventaris}</Table.Cell>
                  <Table.Cell>{kondisi[value.idKondisi]}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
                <Table.Cell>
                  <PlaceholderPara />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Segment>
    </Tab.Pane>
  );
};

const Content = (props) => {
  // const panes = [
  //   { menuItem: "Sinkronisasi", render: () => <Migrasi /> },
  //   { menuItem: "Data Inventaris", render: () => <Data {...props} /> },
  // ];
  return (
    // <Container style={{ padding: 10, width: "100%" }}>
    //   {/* <Subheader label="SEKILAS DATA" /> */}
    //   <Tab panes={panes} />
    // </Container>
    <Container style={{ padding: 10 }}>
      <Data {...props} />
    </Container>
  );
};

const KondisiKoleksi = () => {
  const height = useWindowHeight();
  return (
    <div>
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <MediaContextProvider>
        <Media between={["mobile", "tablet"]}>
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height - 70,
              maxHeight: height - 80,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/koleksi_keramik_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <Content size="mobile" />
          </div>
        </Media>
        <Media at="tablet">
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height - 70,
              maxHeight: height - 80,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/koleksi_keramik_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <Content size="tablet" />
          </div>
        </Media>
        <Media greaterThanOrEqual="computer">
          <Grid columns={2} style={{ height: "90vh" }}>
            <Grid.Row stretched>
              <Grid.Column
                stretched
                width={6}
                // textAlign="center"
                verticalAlign="middle"
                style={{
                  height: "100%",
                  backgroundImage: `url(${"images/koleksi_keramik_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  // marginTop: 15,
                }}
              >
                <Grid stretched>
                  <Grid.Column stretched verticalAlign="middle">
                    <Head />
                    {/* <Breadcrumb icon="right angle" sections={sections} style={{ marginLeft: 20 }} />
              <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
                Aplikasi Database Koleksi
              </Header> */}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column width={10} style={{ backgroundColor: "#eee" }}>
                <Content size="computer" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
      {/* <Header as="h3">Dashboard</Header>
<Image src="/images/paragraph.png" /> */}
    </div>
  );
};

export default KondisiKoleksi;
