import React, { useState } from "react";
import { Popup, Form, Divider, List, Image } from "semantic-ui-react";
import { useAxiosWithToken } from "utils/AxiosWithToken";

const DropdownObservasi = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const [selected, setSelected] = useState(props.value);
  const [selectedText, setSelectedText] = useState(props.text);

  const [{ data, loading, error }, refetch] = useAxiosWithToken(
    "/ref/observasi"
  );
  const position = props.position ? props.position : "top center";

  if (loading) return "loading list..";
  if (error) return "error when loading list of observasis..";

  const handleSelect = (v, t) => {
    setSelected(v);
    setSelectedText(t);
    handleClose();

    console.log(v, t);
    props.onChange(v, t);
  };

  // const handleItemClick = (e, data, t) => {
  //   // console.log(data);
  //   // setSelected(v);
  //   // setSelectedText(t);

  //   // console.log(v, t);
  //   // props.onChange(v, t);
  // };

  const options = data.map((val, key) => {
    // console.log(val);
    return {
      key: val.id_observasi,
      value: val.id_observasi,
      text: val.no_observasi + " - " + val.judul_observasi,
    };
  });

  return (
    <div>
      <Popup
        style={{ padding: 0 }}
        on="click"
        position={position}
        open={isOpen}
        onOpen={handleOpen}
        // content={options.map((val, key) => (
        //   <div key={key}>
        //     <div style={{ padding: 10 }}>
        //       <a onClick={() => handleSelect(val.value, val.text)}>
        //         {val.text}
        //       </a>
        //     </div>
        //     <Divider fitted />
        //   </div>
        // ))}
        trigger={<Form.Input value={selectedText} icon="angle up" />}
        content={
          <List selection verticalAlign="middle">
            {options.map((val, key) => (
              <List.Item
                key={key}
                style={{ padding: 10 }}
                onClick={() => handleSelect(val.value, val.text)}
              >
                <List.Content>
                  <List.Header>{val.text}</List.Header>
                </List.Content>
              </List.Item>
            ))}
          </List>
        }
      />
    </div>
  );
};

export default DropdownObservasi;
