import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { useAxiosWithToken } from "utils/AxiosWithToken";

const DropdownBahanKoleksi = (props) => {
  // const [selected, setSelected] = useState(props.value);
  // const [selectedText, setSelectedText] = useState(props.text);

  const [{ data, loading, error }, refetch] = useAxiosWithToken(
    "/ref/ref_bahan_koleksi"
  );

  if (loading) return "loading list..";
  if (error) return "error when loading list of ref_bahan_koleksi..";

  const options = data.map((val, key) => {
    return {
      key: val.id_bahan_koleksi,
      value: val.id_bahan_koleksi,
      text: val.bahan_koleksi,
    };
  });

  return (
    <Dropdown
      {...props}
      options={options}
      selection
      search
      value={props.value ? props.value : null}
      // defaultValue={selected}
      onChange={(e, f) => {
        // handleChange(f.value);
        props.onChange(f.value);
      }}
      style={{ minWidth: 120 }}
    />
  );
};

export default DropdownBahanKoleksi;
