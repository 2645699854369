import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Menu, Sidebar } from "semantic-ui-react";
// import { toggleSidebar } from "store/actions/ui";
import { logout } from "store/actions/auth";

import {
  MenuBeranda,
  MenuInventarisasi,
  // MenuKondisiKoleksi,
  MenuObservasi,
  MenuLaporan,
} from "components/elements/MenuItems";
const SidebarMenu = (props) => {
  const { sideBarMenuVisible } = useSelector((state) => state.uiReducer);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    history.push("/login");
    dispatch(logout());
  };

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      // onHide={() => dispatch(toggleSidebar())}
      visible={sideBarMenuVisible}
      direction="right"
      vertical
      size="huge"
    >
      <Menu.Item>
        <Menu.Header>Menu</Menu.Header>
        <Menu.Menu>
          <MenuBeranda {...props} />
          <MenuInventarisasi {...props} />
          {/* <MenuKondisiKoleksi {...props} /> */}
          <MenuObservasi {...props} />
          <MenuLaporan {...props} />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>Bantuan</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            name="Unduhan"
            // active={activeItem === "rails"}
            // onClick={this.handleItemClick}
          />
          <Menu.Item
            name="Tentang Aplikasi"
            // active={activeItem === "python"}
            // onClick={this.handleItemClick}
          />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>User</Menu.Header>
        <Menu.Menu>
          <Menu.Item name="Profil" />
          <Menu.Item name="Setting" onClick={handleLogout} />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
    </Sidebar>
  );
};

export default SidebarMenu;
