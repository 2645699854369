import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MediaContextProvider, Media } from "utils/MediaUtils";
import { useWindowHeight } from "@react-hook/window-size";

import {
  Breadcrumb,
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
  Tab,
  Table,
  Sidebar,
  Message,
  Menu,
} from "semantic-ui-react";
import Pengguna from "./setting/Pengguna";
import Unduhan from "./setting/Unduhan";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";

const Head = (props) => {
  const sections = [
    { key: "beranda", content: "Beranda", link: true },
    { key: "settings", content: "Setting", link: true },
  ];
  return (
    <div>
      <Breadcrumb
        icon="right angle"
        sections={sections}
        style={{ marginLeft: 20 }}
      />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        Setting
      </Header>
    </div>
  );
};

const Content = (props) => {
  const { activeTabObservasi } = useSelector((state) => state.uiReducer);

  const dispatch = useDispatch();
  const height = useWindowHeight();
  const panes = [
    {
      menuItem: "Pengguna",
      render: () => <Pengguna callToast={callToast} {...props} />,
    },
    { menuItem: "Unduhan", render: () => <Unduhan /> },
    // { menuItem: "Laporan", render: () => <PenugasanObservasi /> },
  ];
  return (
    <Container style={{ padding: 10, width: "100%" }}>
      {/* <Subheader label="SEKILAS DATA" /> */}
      <Tab panes={panes} onTabChange={handleTabChange} />
    </Container>
  );
};

const handleTabChange = (e, { activeIndex }) => {};

const callToast = (title, type, message) => {
  toast(
    {
      title: title,
      type: type,
      description: `${message}`,
    },
    () => console.log("toast closed"),
    () => console.log("toast clicked"),
    () => console.log("toast dismissed")
  );
};

const Setting = () => {
  const height = useWindowHeight();
  return (
    <div>
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <Sidebar.Pushable>
        <MediaContextProvider>
          <Media between={["mobile", "tablet"]}>
            <div
              style={{
                backgroundColor: "#eee",
                minHeight: height - 70,
                maxHeight: height - 80,
              }}
            >
              <Grid columns={1} style={{ marginTop: 0, marginBottom: 0 }}>
                <Grid.Column
                  style={{
                    height: "20%",
                    backgroundImage: `url(${"images/foto_museum_pegawai_blur.jpg"})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Head />
                </Grid.Column>
              </Grid>
              <Content size="mobile" />
            </div>
          </Media>
          <Media at="tablet">
            <div
              style={{
                backgroundColor: "#eee",
                minHeight: height - 70,
                maxHeight: height - 80,
              }}
            >
              <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
                <Grid.Column
                  style={{
                    height: "20%",
                    backgroundImage: `url(${"images/foto_museum_pegawai_blur.jpg"})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Head />
                </Grid.Column>
              </Grid>
              <Content size="tablet" />
            </div>
          </Media>
          <Media greaterThanOrEqual="computer">
            <Grid columns={2}>
              <Grid.Row stretched>
                <Grid.Column
                  stretched
                  width={6}
                  verticalAlign="middle"
                  style={{
                    height: "100%",
                    backgroundImage: `url(${"images/foto_museum_pegawai_blur.jpg"})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Grid stretched>
                    <Grid.Column stretched verticalAlign="middle">
                      <Head />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column
                  width={10}
                  style={{
                    backgroundColor: "#eee",
                    minHeight: height - 87,
                    maxHeight: height - 87,
                  }}
                >
                  <Content size="computer" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Media>
        </MediaContextProvider>
      </Sidebar.Pushable>
      <SemanticToastContainer />
    </div>
  );
};

export default Setting;
