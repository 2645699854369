import React from "react";
import { Icon, Segment, Statistic } from "semantic-ui-react";

const StatNumber = (props) => {
  const { icon, number, label, color } = props;
  return (
    <Segment style={{ textAlign: "center" }}>
      <Statistic size="mini">
        <Statistic.Value>
          <Icon name={icon} style={{ color: color }} />
          <span className="ui">{number.toLocaleString()}</span>
        </Statistic.Value>
        <Statistic.Label>
          <span
            style={{
              color: "#888",
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            {label}
          </span>
        </Statistic.Label>
      </Statistic>
    </Segment>
  );
};

export default StatNumber;
