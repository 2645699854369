import ProfileForm from "components/auth/ProfileForm";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Image, Menu, Popup, Divider } from "semantic-ui-react";
import { logout, refreshUserData } from "store/actions/auth";
import { MenuSetting } from "./MenuItems";
import { API_URL } from "env";

const apiUrl = API_URL;

const Avatar = ({ size }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const handleLogout = () => {
    history.push("/login");
    dispatch(logout());
  };

  var picture = "";

  useEffect(() => {
    if (auth) {
      setUserProfile(auth);
    }
  }, [auth]);

  const handleShowProfile = () => {
    setShowProfile(true);
  };

  const [showProfile, setShowProfile] = useState(false);
  const [userProfile, setUserProfile] = useState({
    id: "",
    username: "",
    email: "",
    password: "",
    realm: "",
    picture: "",
  });

  const { id, username, email, password, realm } = userProfile;
  console.log(userProfile);

  var picture = "";
  if (id !== "") {
    picture = `${apiUrl}/uploads/${userProfile.picture.replace("./", "")}`;
    console.log(picture);
  }

  const onFinish = (success, response) => {
    console.log("Finished profile! ");
    dispatch(refreshUserData(email));
    if (success) {
      setShowProfile(false);
    }
  };

  return email ? (
    <Menu.Item position="right">
      <Popup
        size="tiny"
        hoverable
        trigger={
          <div>
            {size !== "small" ? username : ""}
            {picture !== "" ? <Image avatar src={picture} spaced={"left"} /> : <Image avatar src="/icons/avatar_human.png" spaced={"left"} />}
          </div>
        }
      >
        <Menu vertical secondary size="large">
          <Menu.Item name="Profil" onClick={handleShowProfile} />
          {/* <Menu.Item name="Setting" /> */}
          {realm === "admins" ? <MenuSetting /> : ""}
          <Divider />
          <Menu.Item name="Keluar" onClick={handleLogout} />
        </Menu>
      </Popup>
      {id ? (
        <ProfileForm showProfile={showProfile} setShowProfile={setShowProfile} userProfile={userProfile} onFinish={onFinish} title="Profil" />
      ) : (
        <div />
      )}
    </Menu.Item>
  ) : (
    <div />
  );
};

export default Avatar;
