import React, { useState } from "react";
// import { useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { MediaContextProvider, Media } from "utils/MediaUtils";
import { useDispatch, useSelector } from "react-redux";
import { login, toggleIsLoading } from "store/actions/auth";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";

import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";

const Head = (props) => {
  if (props.size === "small")
    return <Header size="tiny">Aplikasi Database Koleksi</Header>;
  return (
    <div>
      <Header
        inverted
        size="huge"
        style={{ marginTop: 0, marginBottom: 0, marginLeft: 20 }}
      >
        Aplikasi Database Koleksi
      </Header>
      <Header
        size="mini"
        style={{ marginTop: 0, marginBottom: 0, marginLeft: 20 }}
      >
        Museum Nasional Indonesia
      </Header>
    </div>
  );
};

const Content = (props) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.authReducer);

  // Callback if login success: reroute to root
  const history = useHistory();
  const onLoginSuccess = () => {
    console.log("Successful Login!");
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  const onLoginFailed = (status) => {
    toast(
      {
        title: "Error",
        type: "error",
        description: `${status}`,
      },
      () => console.log("toast closed"),
      () => console.log("toast clicked"),
      () => console.log("toast dismissed")
    );
  };

  // On login button pressed
  const onLoginClick = () => {
    // Set loading to the button
    dispatch(toggleIsLoading(true));
    // Process login
    dispatch(login(email, password, onLoginSuccess, onLoginFailed));
  };

  // State for email/password login form
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const formSize = props.size === "computer" ? "large" : "tiny";
  const gridMaxWidth = props.size === "computer" ? 450 : 400;
  const headerAs = props.size === "computer" ? "h1" : "h2";
  return (
    <Container style={{ height: "100vh", width: "100%" }}>
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: gridMaxWidth }}>
          <Form size={formSize}>
            <Segment>
              <Header as={headerAs} color="brown" textAlign="center">
                <Image src="/logo/logo-color.png" />
                {props.withHead ? <Head size="small" /> : <div />}
              </Header>
              <Form.Input
                fluid
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                icon="mail"
                iconPosition="left"
                placeholder="E-mail address"
              />
              <Form.Input
                fluid
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
              />
              <Button
                color="brown"
                fluid
                size="large"
                onClick={onLoginClick}
                loading={isLoading}
              >
                Login
              </Button>
            </Segment>
          </Form>
          {/* <Message>
            <a href="#">Lupa password</a>
          </Message> */}
        </Grid.Column>
      </Grid>
    </Container>
  );
};

const Login = () => {
  return (
    <MediaContextProvider>
      {/* <Media between={["mobile", "tablet"]}>
        <div style={{ backgroundColor: "#fff" }}>
          <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
            <Grid.Column
              style={{
                height: "20%",
                backgroundImage: `url(${"images/foto_museum_blur.jpg"})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Head />
            </Grid.Column>
          </Grid>
          <Content size="mobile" />
        </div>
      </Media> */}
      <Media between={["mobile", "computer"]}>
        <div style={{ backgroundColor: "#fff" }}>
          <Grid columns={1} style={{ marginTop: 0, marginBottom: 0 }}>
            <Grid.Column
              style={{
                height: "20%",
                backgroundImage: `url(${"images/foto_museum_blur.jpg"})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Content size="tablet" withHead={true} />
            </Grid.Column>
          </Grid>
        </div>
      </Media>
      <Media greaterThanOrEqual="computer">
        <Grid columns={2} style={{ height: "100%" }}>
          <Grid.Row stretched>
            <Grid.Column
              stretched
              width={8}
              // textAlign="center"
              verticalAlign="middle"
              style={{
                height: "100%",
                backgroundImage: `url(${"images/foto_museum_blur.jpg"})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                // marginTop: 15,
              }}
            >
              <Grid stretched>
                <Grid.Column stretched verticalAlign="middle">
                  <Head />
                  {/* <Breadcrumb icon="right angle" sections={sections} style={{ marginLeft: 20 }} />
              <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
                Aplikasi Database Koleksi
              </Header> */}
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column width={8} style={{ backgroundColor: "#fff" }}>
              <Content size="computer" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Media>
      <SemanticToastContainer />
    </MediaContextProvider>
  );
};

export default Login;
