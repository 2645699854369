import * as data from "../actions/data";

const INITIAL_STATE = {
  isLoading: false,
  method: "",
  model: "",
  success: null,
  status: "",
  message: "",
  idObservasi: "",
  deskripsiObservasi: "",
  idObservasiRinci: "",
  obyekObservasiRinci: {},
  deskripsiObyekObservasi: "",
  cartObservasi: [],
  editModel: "",
  editParam: {},
  editData: "",
};

const dataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case data.API_POST:
      return {
        ...state,
        method: action.method,
        model: action.model,
        success: action.success,
        status: action.status,
        message: action.message,
      };
    case data.TOGGLE_ISLOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case data.TOGGLE_IDOBSERVASI:
      return {
        ...state,
        idObservasi: action.idObservasi,
        deskripsiObservasi: action.deskripsiObservasi,
      };
    case data.TOGGLE_IDOBSERVASIRINCI:
      console.log(
        "Rediucer TOGGLE_IDOBSERVASIRINCI kicked: ",
        action.idObservasiRinci,
        action.obyekObservasiRinci,
        action.deskripsiObyekObservasi
      );
      return {
        ...state,
        idObservasiRinci: action.idObservasiRinci,
        obyekObservasiRinci: action.obyekObservasiRinci,
        deskripsiObyekObservasi: action.deskripsiObyekObservasi,
      };

    case data.INSERT_CARTOBSERVASI:
      return {
        ...state,
        cartObservasi: [...state.cartObservasi, action.data],
      };

    case data.DELETE_CARTOBSERVASI:
      return {
        ...state,
        cartObservasi: state.cartObservasi.filter(
          (cart) => cart.id_benda !== action.id_benda
        ),
      };

    case data.EDIT_FORM:
      return {
        ...state,
        editModel: action.editModel,
        editParam: action.editParam,
        editData: action.editData,
      };

    case data.RESET_EDIT_FORM:
      return {
        ...state,
        editModel: INITIAL_STATE.editModel,
        editParam: INITIAL_STATE.editParam,
        editData: INITIAL_STATE.editData,
      };

    default:
      return state;
  }
};

export default dataReducer;
