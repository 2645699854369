import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebarMenu } from "store/actions/ui";

export const MenuItem = (props) => {
  const { sideBarMenuVisible } = useSelector((state) => state.uiReducer);
  const dispatch = useDispatch();

  let style = {};
  let activeStyle = props.active ? styles.active : {};
  let largeStyle = sideBarMenuVisible ? {} : styles.large;

  style = { ...activeStyle, ...largeStyle };

  const handleClick = () => {
    console.log("Menu clicked");
    if (sideBarMenuVisible) dispatch(toggleSidebarMenu());
  };
  return (
    <Menu.Item {...props} style={style} onClick={handleClick}>
      {props.children}
    </Menu.Item>
  );
};

const styles = {
  large: {
    padding: 20,
  },
  active: {
    fontWeight: "bolder",
  },
};

export const MenuBeranda = (props) => {
  // const activeMenu = useSelector((state) => state.uiReducer.activeMenu);
  const isActive = props.activeMenu === "beranda";

  return (
    <MenuItem
      as={Link}
      to="/"
      name="Beranda"
      active={isActive}
      onClick={props.onClick}
    >
      Beranda
    </MenuItem>
  );
};

export const MenuInventarisasi = (props) => {
  // const activeMenu = useSelector((state) => state.uiReducer.activeMenu);
  const isActive = props.activeMenu === "inventarisasi";

  return (
    <MenuItem
      as={Link}
      to="/inventarisasi"
      name="Inventarisasi"
      active={isActive}
    >
      Inventarisasi
    </MenuItem>
  );
};

export const MenuKondisiKoleksi = (props) => {
  // const activeMenu = useSelector((state) => state.uiReducer.activeMenu);
  const isActive = props.activeMenu === "kondisi_koleksi";
  return (
    <MenuItem
      as={Link}
      to="/kondisi_koleksi"
      name="Kondisi Koleksi"
      active={isActive}
    >
      Kondisi Koleksi
    </MenuItem>
  );
};

export const MenuObservasi = (props) => {
  // const activeMenu = useSelector((state) => state.uiReducer.activeMenu);
  const isActive = props.activeMenu === "rencana_observasi";
  return (
    <MenuItem
      as={Link}
      to="/rencana_observasi"
      name="Rencana Observasi"
      active={isActive}
    >
      Rencana Observasi
    </MenuItem>
  );
};

export const MenuLaporan = (props) => {
  // const activeMenu = useSelector((state) => state.uiReducer.activeMenu);
  const isActive = props.activeMenu === "laporan_observasi";
  return (
    <MenuItem
      as={Link}
      to="/laporan_observasi"
      name="Laporan Observasi"
      active={isActive}
    >
      Laporan Observasi
    </MenuItem>
  );
};
// // setting
// export const MenuPengguna = (props) => {
//   // const activeMenu = useSelector((state) => state.uiReducer.activeMenu);
//   const isActive = props.activeMenu === "userslist";
//   return (
//     <Dropdown.Item as={Link} to="/userslist" name="Daftar Pengguna" active={isActive}>
//       Daftar Pengguna
//     </Dropdown.Item>
//   );
// };

// setting
export const MenuSetting = (props) => {
  // const activeMenu = useSelector((state) => state.uiReducer.activeMenu);
  const isActive = props.activeMenu === "setting";
  return (
    <Dropdown.Item as={Link} to="/setting" name="Setting" active={isActive}>
      Setting
    </Dropdown.Item>
  );
};

export const MenuUnduhan = (props) => {
  // const activeMenu = useSelector((state) => state.uiReducer.activeMenu);
  const isActive = props.activeMenu === "unduhan";
  return (
    <Dropdown.Item as={Link} to="/unduhan" name="Unduhan" active={isActive}>
      Unduhan
    </Dropdown.Item>
  );
};

export const MenuTentangAplikasi = (props) => {
  // const activeMenu = useSelector((state) => state.uiReducer.activeMenu);
  const isActive = props.activeMenu === "tentang_aplikasi";
  return (
    <Dropdown.Item
      as={Link}
      to="/tentang_aplikasi"
      name="Tentang Aplikasi"
      active={isActive}
    >
      Tentang Aplikasi
    </Dropdown.Item>
  );
};
