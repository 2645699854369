import React, { useState } from "react";
import {
  Breadcrumb,
  Container,
  Divider,
  Grid,
  Header,
  Progress,
  Button,
  Icon,
  Segment,
  Tab,
  Table,
  Message,
  Menu,
  Image,
} from "semantic-ui-react";
import { MediaContextProvider, Media } from "utils/MediaUtils";
import StatNumber from "components/elements/StatNumber";
import Subheader from "components/elements/Subheader";
import { useWindowHeight } from "@react-hook/window-size";

import { toggleSidebarForm } from "store/actions/ui";
import { useDispatch, useSelector } from "react-redux";
import PlaceholderPara from "components/elements/PlaceholderPara";

import { useAxiosWithToken } from "utils/AxiosWithToken";

const Head = (props) => {
  const sections = [
    { key: "beranda", content: "Beranda", link: true },
    { key: "inventarisasi", content: "Inventarisasi", link: true },
  ];
  return (
    <div>
      <Breadcrumb
        icon="right angle"
        sections={sections}
        style={{ marginLeft: 20 }}
      />
      <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
        Daftar UnduhanView
      </Header>
    </div>
  );
};

const Content = () => {
  const height = useWindowHeight();
  return (
    <div style={{ paddingTop: 10 }}>
      <Segment>
        {/* <Form.Field inline>
      {/* <Input icon="search" placeholder="Cari.." /> */}
        {/* </Form.Field> */}
        <Segment style={{ padding: 0, overflow: "auto", maxHeight: height }}>
          {/* {error ? <Message>There is an error: {error}</Message> : ""} */}
          <Table
            selectable
            structured
            celled
            striped
            style={{ borderWidth: 0 }}
            unstackable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                <Table.HeaderCell width={3}>Nama UnduhanView</Table.HeaderCell>
                <Table.HeaderCell width={2}>Jenis File</Table.HeaderCell>
                <Table.HeaderCell width={2}>Ukuran</Table.HeaderCell>
                <Table.HeaderCell width={5}>Keterangan</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>1</Table.Cell>
                <Table.Cell>Dokumen Manual</Table.Cell>
                <Table.Cell>
                  <Icon name="file pdf" />
                  PDF
                </Table.Cell>
                <Table.Cell>126KB</Table.Cell>
                <Table.Cell>
                  Dokumen unduhan ini memuat panduan cara menggunakan aplikasi.
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>2</Table.Cell>
                <Table.Cell>Prosedur Observasi</Table.Cell>
                <Table.Cell>
                  <Icon name="file pdf" />
                  PDF
                </Table.Cell>
                <Table.Cell>202KB</Table.Cell>
                <Table.Cell>
                  Dokumen unduhan ini merupakan acuan Prosedur Pelaksanaan
                  Observasi Koleksi Museum Nasional.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    </div>
  );
};
const UnduhanView = () => {
  const height = useWindowHeight();
  return (
    <div>
      {/* <div style={{ backgroundColor: "#eee", width }}/> */}
      <div className="divshadow" style={{ height: 1 }}>
        &nbsp;
      </div>
      <MediaContextProvider>
        <Media between={["mobile", "tablet"]}>
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height - 70,
              maxHeight: height - 80,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/foto_books_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <div style={{ overflow: "scroll", maxHeight: height - 100 }}>
              <Content />
            </div>
          </div>
        </Media>
        <Media at="tablet">
          <div
            style={{
              backgroundColor: "#eee",
              minHeight: height,
              maxHeight: height,
            }}
          >
            <Grid columns={1} style={{ marginTop: 0, marginBottom: 10 }}>
              <Grid.Column
                style={{
                  height: "20%",
                  backgroundImage: `url(${"images/foto_books_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Head />
              </Grid.Column>
            </Grid>
            <Content />
          </div>
        </Media>
        <Media greaterThanOrEqual="computer">
          <Grid columns={2} style={{ height: "90vh" }}>
            <Grid.Row stretched>
              <Grid.Column
                stretched
                width={6}
                // textAlign="center"
                verticalAlign="middle"
                style={{
                  height: "100%",
                  backgroundImage: `url(${"images/foto_books_blur.jpg"})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  // marginTop: 15,
                }}
              >
                <Grid stretched>
                  <Grid.Column stretched verticalAlign="middle">
                    <Head />
                    {/* <Breadcrumb icon="right angle" sections={sections} style={{ marginLeft: 20 }} />
                <Header inverted size="huge" style={{ marginTop: 0, marginLeft: 20 }}>
                  Aplikasi Database Koleksi
                </Header> */}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column width={10} style={{ backgroundColor: "#eee" }}>
                <Content />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
      {/* <Header as="h3">Dashboard</Header>
  <Image src="/images/paragraph.png" /> */}
    </div>
  );
};

export default UnduhanView;
