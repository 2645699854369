import axios from "axios";
import { axiosWithToken } from "utils/AxiosWithToken";
import { API_URL } from "env";

export const LOGIN = "LOGIN";
export const REFRESH_USER_DATA = "REFRESH_USER_DATA";
export const LOGOUT = "LOGOUT";
export const TOGGLE_ISLOADING = "TOGGLE_ISLOADING";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

const apiUrl = API_URL;

export const login = (email, password, onLoginSuccess, onLoginFailed) => async (dispatch) => {
  try {
    // Get Token
    let responseTk = await axios.post(apiUrl + "/api/login", {
      email,
      password,
    });
    let { token, id, username, realm, picture } = responseTk.data;

    // Callback
    dispatch(toggleIsLoading(false));
    onLoginSuccess();
    dispatch({
      type: LOGIN,
      token: token,
      id: id,
      email: email,
      username: username,
      realm: realm,
      picture: picture,
    });
  } catch (e) {
    console.log(e);
    dispatch(toggleIsLoading(false));
    var errMessage = "";
    onLoginFailed(errMessage);
  }
};

export const refreshUserData = (email) => async (dispatch) => {
  try {
    // Get WhoAmi
    let responseWho = await axiosWithToken.get(apiUrl + "/api/whoAmI", {
      params: { email: email },
    });
    let { id, realm, username, picture } = responseWho.data[0];
    console.log(id, realm, username, picture);
    // Callback
    dispatch({
      type: REFRESH_USER_DATA,
      email: email,
      id: id,
      realm: realm,
      username: username,
      picture: picture,
    });
  } catch (e) {
    throw e;
  }
};

export const logout = () => {
  return { type: LOGOUT };
};

export const toggleIsLoading = (isLoading) => {
  return { type: TOGGLE_ISLOADING, isLoading: isLoading };
};

export const showMessage = (message) => {
  return { type: SHOW_MESSAGE, message: message };
};

export const clearMessage = () => {
  return { type: CLEAR_MESSAGE };
};
